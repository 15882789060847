<div class="eco-21-cntr contacts-cntr h-100">

  <title-heading [titleHeadingViewModel]="{ title: 'Contatti', subtitle: 'Scrivici per maggiori informazioni'}">
  </title-heading>

  <form [formGroup]="form">

    <div class="container h-100">

      <form-input [parameters]="name"></form-input>

      <form-input [parameters]="email"></form-input>

      <app-text-area [textAreaViewModel]="{ control: form.get('message'), label: 'Scrivi il tuo messaggio'}">
      </app-text-area>

    </div>

    <div class="mt-4 m-auto text-center">

      <ct-button [parameters]="addButton"></ct-button>

    </div>

  </form>

  <!-- <app-contacts-carousel></app-contacts-carousel> -->

</div>