import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RankComponent} from './rank.component';
import {MatLegacyTabsModule as MatTabsModule} from '@angular/material/legacy-tabs';
import {UserCardModule} from './user-card/user-card.module';
import {RankController} from "../../core/controllers/rank-controller";
import { TitleHeadingModule } from 'src/app/components/title-heading/title-heading.module';

@NgModule({
  imports: [
    CommonModule,
    MatTabsModule,
    UserCardModule,
    TitleHeadingModule
  ],
  declarations: [RankComponent],
  providers: [RankController],
  exports: [RankComponent]
})
export class RankModule {
}

