import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SettingsComponent} from './settings.component';
import {EditPasswordComponent} from './edit-password/edit-password.component';
import {EditProfileComponent} from './edit-profile/edit-profile.component';
import {ContactsComponent} from './contacts/contacts.component';
import {CtButtonModule} from '@ctsolution/ct-framework';
import {TitleHeadingModule} from 'src/app/components/title-heading/title-heading.module';
import {FormInputModule} from 'src/app/components/form-input/form-input.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatLegacyCheckboxModule as MatCheckboxModule} from '@angular/material/legacy-checkbox';
import {MatLegacyFormFieldModule as MatFormFieldModule} from '@angular/material/legacy-form-field';
import {MatLegacyInputModule as MatInputModule} from '@angular/material/legacy-input';
import {TextAreaComponent} from './contacts/text-area/text-area.component';
import {MatIconModule} from '@angular/material/icon';
import {CookiesComponent} from './cookies/cookies.component';
import { PasswordController } from 'src/app/core/controllers/password-controller';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { IvyCarouselModule } from 'angular-responsive-carousel';
import { DeleteProfileComponent } from './delete-profile/delete-profile.component';
import { ContactsCarouselComponent } from './contacts/contacts-carousel/contacts-carousel.component';


@NgModule({
  imports: [
    CommonModule,
    CtButtonModule,
    TitleHeadingModule,
    FormInputModule,
    FormsModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    MatCheckboxModule,
    NgbPopoverModule,
    MatIconModule,
    IvyCarouselModule

    
  ],
  declarations: [
    SettingsComponent,
    EditPasswordComponent,
    EditProfileComponent,
    ContactsComponent,
    TextAreaComponent,
    ContactsCarouselComponent,
    CookiesComponent,
    DeleteProfileComponent
  ],
  exports: [
    SettingsComponent,
    EditPasswordComponent,
    EditProfileComponent,
    ContactsComponent,
    TextAreaComponent,
    CookiesComponent,
    DeleteProfileComponent
  ],
  providers: [PasswordController]
})
export class SettingsModule {
}
