import {RANKING_TYPE} from '../enums/ranking-type';

export class RankingGetParameter {

  referringDate: string;

  constructor(
    public type: RANKING_TYPE,
    referringDate: Date
  ) {

    this.referringDate = referringDate.toISOString().substring(0, 10);

  }

}
