<ng-container *ngIf="card ; else businessTemplate">

<ct-card *ngIf="cardParameter" [parameters]="cardParameter"></ct-card>


</ng-container>

<ng-template #businessTemplate>

  <div class="d-flex">
    <img *ngIf="cardBusiness" class="partners-img"src={{cardBusiness.smallimmagine}}>
    <ct-card *ngIf="cardParameter" [parameters]="cardParameter"></ct-card>
    </div>

</ng-template>
