import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute } from '@angular/router';
import { MissionsController } from 'src/app/core/controllers/missions-controller';
import { QuestionarioController } from 'src/app/core/controllers/questionario-controller';
import {AppGeneralService} from 'src/app/core/lib/app-general.service';
import * as $ from 'jquery';
import { QuizCompletedComponent } from 'src/app/components/quiz-completed/quiz-completed.component';

export class UserAnswer {
  idRisposta!: number;
}

export class SurveyAnswer {
  id!: number;
  testo!: string;
  UserAnswer!: UserAnswer;
}

export class SurveyQuestion {
  id!: number;
  testo!: string;
  risposte!: Array<SurveyAnswer>;
  rispostaUtente!: UserAnswer;
}

export class Survey {
  id!: number;
  nome!: string;
  domande!: Array<SurveyQuestion>;
}

export class SurveyAnswerParam {
  QuestionId: number;
  AnswerId: number;

  constructor(
    QuestionId: number,
    AnswerId: number
  ) {
    this.QuestionId = QuestionId;
    this.AnswerId = AnswerId;
  }
}

@Component({
  selector: 'app-quiz',
  templateUrl: './quiz.component.html',
  styleUrls: ['./quiz.component.scss']
})
export class QuizComponent implements OnInit {

  SurveyID: number = 0;
   Questionario!: Survey;
  SurveyForm: UntypedFormGroup;

  FormSubmitted: boolean = false;
  SurveyEdit: boolean = false;




  constructor(
    private formBuilder: UntypedFormBuilder,
    public appGeneralService: AppGeneralService,
    private questionarioController: QuestionarioController,
    private route: ActivatedRoute,
    private missionController: MissionsController,
    private matDialog: MatDialog
  ) {

    const survey: any =  this.route.snapshot.paramMap.get('surveyid');

    this.SurveyForm = this.formBuilder.group({});
     this.SurveyID = survey;
    this.questionarioController.getSurvey(this.SurveyID).subscribe((result: any) => {
      this.Questionario = result['result'];
      this.Questionario.domande.forEach((question) => {

        this.SurveyForm.addControl(question.id.toString(), new UntypedFormControl('', [Validators.required]));

        const surveyFormValue: string | any =  this.SurveyForm.get(question.id.toString())

        if (question.rispostaUtente !== null && question.rispostaUtente.idRisposta > 0) {
          surveyFormValue.setValue(question.rispostaUtente.idRisposta);
          surveyFormValue.disable();
          this.SurveyEdit = true;
        }

      });

    });

  }

  ngOnInit() {
  }

  SendForm() {
    this.FormSubmitted = true;
    if (this.SurveyForm.valid) {

      const FormSurvey = this.SurveyForm.value;
      const FormKeys = Object.keys(FormSurvey);

      let SurveyParam: SurveyAnswerParam[] = [];
      FormKeys.forEach((control) => { SurveyParam.push(new SurveyAnswerParam(+control, FormSurvey[control])) });

      this.questionarioController.postSurvey(this.SurveyID, SurveyParam).subscribe((result:any) => {

        const dialogRef = this.matDialog.open(QuizCompletedComponent, {
          width: '300px',
          data: result['result']['totalePunti'],
          panelClass: 'plastipremia-dialog'
        });

        dialogRef.afterClosed().subscribe(() => {
          this.appGeneralService.back();
          this.missionController.update().subscribe();
        })
      })

    } else {
      // setTimeout(() => {
      //   $('html, body').animate({
      //     scrollTop: $('.mat-error').first().offset().top - 150
      //   }, 1000);
      // }, 0);
    }

  }

}
