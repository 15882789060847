import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SignInComponent } from './sign-in.component';
import { RouterModule } from '@angular/router';
import { CtButtonModule } from '@ctsolution/ct-framework';
import { LoginModule } from './login/login.module';


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    CtButtonModule,
    LoginModule
  ],
  declarations: [SignInComponent],
  exports: [SignInComponent]
})
export class SignInModule { }
