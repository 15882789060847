export class BindersResearch {

  constructor(
    public MacroTypeRisorsa: string,
    public latitudine?: number,
    public longitudine?: number,
    public giorno?: number
  ) {
  }

}
