import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoginComponent} from './authentication/sign-in/login/login.component';
import {RecoveryPasswordComponent} from './authentication/recovery-password/recovery-password.component';
import {SignUpComponent} from './authentication/sign-up/sign-up.component';
import {AffiliateBusinessComponent} from './pages/home/search/affiliate-business/affiliate-business.component';
import {ProfileComponent} from './pages/profile/profile.component';
import {FamiliarComponent} from './pages/profile/familiar/familiar.component';
import {AvatarsComponent} from './pages/profile/avatars/avatars.component';
import {NewsComponent} from './pages/news/news.component';
import {TargetsComponent} from './pages/targets/targets.component';
import {QuizComponent} from './pages/news/available-quiz/quiz/quiz.component';
import {AvailableQuizComponent} from './pages/news/available-quiz/available-quiz.component';
import {SignInComponent} from './authentication/sign-in/sign-in.component';
import {EditProfileComponent} from './pages/settings/edit-profile/edit-profile.component';
import {EditPasswordComponent} from './pages/settings/edit-password/edit-password.component';
import {ContactsComponent} from './pages/settings/contacts/contacts.component';
import {SettingsComponent} from './pages/settings/settings.component';
import {CompactorsComponent} from './pages/home/search/compactors/compactors.component';
import {BinderNewsListComponent} from './pages/news/binder-news-list/binder-news-list.component';
import {HistoricalComponent} from './pages/historical/historical.component';
import {PartnerComponent} from './pages/home/search/affiliate-business/partner/partner.component';
import {LoggedHomeComponent} from './pages/home/logged-home/logged-home.component';
import {RankComponent} from "./pages/rank/rank.component";
import {AuthGuardService} from "./core/lib/auth/auth-guard.service";
import {ConferimentoComponent} from "./pages/conferimento/conferimento.component";
import {ResetPasswordComponent} from "./authentication/reset-password/reset-password.component";
import {EcoCouponsComponent} from './pages/eco-coupons/eco-coupons.component';
import {
  FamiliarRegistrationCompleteComponent
} from './pages/profile/familiar/familiar-registration-complete/familiar-registration-complete.component';

export const routes: Routes = [

  {
    path: 'accesso', children: [

      {path: 'recupero-password', component: RecoveryPasswordComponent},
      {path: 'reset-password', component: ResetPasswordComponent},
      {path: 'login', component: LoginComponent},
      {path: '**', redirectTo: '/accedi'}

    ]

  },
  {path: 'accedi', component: SignInComponent},
  {path: 'registrazione', component: SignUpComponent},
  {path: 'famigliare/completa-registrazione', component: FamiliarRegistrationCompleteComponent},

  {
    path: '',
    canActivate: [AuthGuardService],
    children: [
      {path: 'home', component: LoggedHomeComponent},
      {path: 'compattatori', component: CompactorsComponent},
      {
        path: 'partners', children: [
          {path: 'partner/:partnerid', component: PartnerComponent},
          {path: '', component: AffiliateBusinessComponent},
          {path: '**', redirectTo: ''}
        ]
      },
      {
        path: 'profilo', children: [
          {path: 'avatars', component: AvatarsComponent},
          {path: 'famigliare', component: FamiliarComponent},
          {path: '', component: ProfileComponent},
          {path: '**', redirectTo: ''}
        ]
      },
      
      {path: 'missioni', component: TargetsComponent, canActivate: [AuthGuardService]},
      {
        path: 'coupons', children: [

          {path: '', component: EcoCouponsComponent},
          {path: '**', redirectTo: ''}
        ]
      },
      {path: 'missioni', component: TargetsComponent},
      {path: 'saldo', component: HistoricalComponent},
      {
        path: 'news', children: [
          {path: 'ecovans', component: BinderNewsListComponent},
          {
            path: 'quiz', children: [
              {path: 'questionario/:surveyid', component: QuizComponent},
              {path: '', component: AvailableQuizComponent},
              {path: '**', redirectTo: ''}
            ]
          },
          {path: '', component: NewsComponent},
          {path: '**', redirectTo: ''}
        ]
      },
      {
        path: 'impostazioni', children: [
          {path: 'modifica-profilo', component: EditProfileComponent},
          {path: 'modifica-password', component: EditPasswordComponent},
          {path: 'contatti', component: ContactsComponent},
          {path: '', component: SettingsComponent},
          {path: '**', redirectTo: ''}
        ]
      },
      {path: 'classifica', component: RankComponent},
      {path: 'conferimento', component: ConferimentoComponent},
      {path: '', redirectTo: '/home', pathMatch: 'full'}
    ]
  },
  {path: '**', redirectTo: '/home', pathMatch: 'full'},
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
