<div class="logos-area-cntr mt-4">

  <div class="mobile">

    <carousel [cellsToShow]="1" [autoplay]="true">

      <div class="carousel-cell">

        <img  src="assets/images/avatars-ecoxxi/ariete.svg">

      </div>
      
      <div class="carousel-cell">

        <img src="assets/images/avatars-ecoxxi/asinello.svg">

      </div>

      <div class="carousel-cell">

        <img  src="assets/images/avatars-ecoxxi/balena.svg">

      </div>

      <div class="carousel-cell">

        <img  src="assets/images/avatars-ecoxxi/cane.svg">

      </div>

      <div class="carousel-cell">

        <img  src="assets/images/avatars-ecoxxi/maiale.svg">

      </div>

      <div class="carousel-cell">

        <img src="assets/images/avatars-ecoxxi/procione.svg">

      </div>

      <div class="carousel-cell">

        <img  src="assets/images/avatars-ecoxxi/toro.svg">

      </div>

    </carousel>

  </div>

  <div class="desktop">
    <div class="row">
      <div class="col-md col-3 d-flex">
        <img class="m-auto"  src="assets/images/avatars-ecoxxi/ariete.svg"/>
      </div>
      <div class="col-md col-3 d-flex">
        <img class="m-auto"  src="assets/images/avatars-ecoxxi/ariete.svg"/>
      </div>
      <div class="col-md col-3 d-flex">
        <img class="m-auto"  src="assets/images/avatars-ecoxxi/ariete.svg"/>
      </div>
      <div class="col-md col-3 d-flex">
        <img class="m-auto"  src="assets/images/avatars-ecoxxi/ariete.svg"/>
      </div>
      <div class="col-md col-3 d-flex">
        <img class="m-auto"  src="assets/images/avatars-ecoxxi/ariete.svg"/>
      </div>
      <div class="col-md col-3 d-flex">
        <img class="m-auto"  src="assets/images/avatars-ecoxxi/ariete.svg"/>
      </div>
      <div class="col-md col-3 d-flex">
        <img class="m-auto"  src="assets/images/avatars-ecoxxi/ariete.svg"/>
      </div>
    </div>
  </div>

</div>