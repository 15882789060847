import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ResetPasswordComponent} from './reset-password.component';
import {TitleHeadingModule} from "../../components/title-heading/title-heading.module";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {FormInputModule} from "../../components/form-input/form-input.module";
import {CtButtonModule} from "@ctsolution/ct-framework";
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';

@NgModule({
  declarations: [
    ResetPasswordComponent
  ],
  imports: [
    CommonModule,
    TitleHeadingModule,
    FormsModule,
    ReactiveFormsModule,
    FormInputModule,
    FormsModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    CtButtonModule
  ]
})
export class ResetPasswordModule {
}
