import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {AuthService} from './auth.service';
import {CtWebviewService} from "@ctsolution/ct-webview";

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(public auth: AuthService, public router: Router, private ctWebView: CtWebviewService) {
  }

  loggedUser(): boolean {

    return !this.auth.bearerInexistent() && !this.auth.bearerExpired();

  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

    let returnUrl: string = state.url;

    if (route.queryParams['InApp']) {

      returnUrl = state.url.replace('InApp=true', '');
      this.ctWebView.launchWebView(`${window.location.origin + returnUrl}`);

    }

    if (this.auth.bearerInexistent() || this.auth.bearerExpired()) {

      this.router.navigate(['accedi'], {queryParams: {returnUrl}});
      return false;

    }

    return true;

  }

}
