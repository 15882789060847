<div class="quiz-completed-cntr">

<div class="d-flex justify-content-end">

  <mat-icon class="dark-green font-weight-bolder" (click)="close()">close</mat-icon>

</div>

<div class="d-flex justify-content-center" *ngIf="avatar">

  <div class="avatar" [ngStyle]="{'background': 'url('+avatar.getFullPath()+')'}"></div>

</div>

<h1 class="text-center light-green">Congratulazioni!!!</h1>

<h3 class="text-center font-weight-bolder" *ngIf="points > 0">Hai guadagnato {{points}} punti</h3>

</div>