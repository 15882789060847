import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormInputModule } from 'src/app/components/form-input/form-input.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyOptionModule as MatOptionModule } from '@angular/material/legacy-core';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { SelectOptionComponent } from './select-option.component';

@NgModule({
  imports: [
    CommonModule,
    FormInputModule,
    FormsModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    MatSelectModule,
    MatOptionModule

  ],
  declarations: [SelectOptionComponent],
  exports: [SelectOptionComponent]
})
export class SelectOptionModule { }
