import {Injectable} from '@angular/core';
import {MatLegacySnackBar as MatSnackBar} from "@angular/material/legacy-snack-bar";

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {

  constructor(private snackbar: MatSnackBar) {
  }

  openGenericSnackBar = (message: string, duration: number = 3500) => this.snackbar.open(message, 'X', {duration: 3500});

}
