
<h1>Come funziona?</h1>

<div class="tutorial_cntr ml-2 mr-2 mb-2">

  <h3><b>COSA INSERIRE:</b></h3>
  <p>Contenitori in plastica per liquidi: bottiglie di acqua/succo/bibite varie, flaconi di creme e salse alimentari
    (es. ketchup), flaconi di shampoo/saponi liquidi/creme solari, flaconi di prodotti per la detergenza della casa:
    sapone per i piatti, detersivi, detergenti, ecc.</p>
  <h3><b>COME FUNZIONA:</b></h3>
  <p>Con PlasTiPremia accumuli punti inserendo bottiglie e flaconi di plastica nel raccoglitore. Ogni contenitore
    inserito equivale a 1 punto.</p>
  <p>Prima del conferimento, separa il tappo dalla bottiglia/flacone. I tappi vanno inseriti nelle rispettive
    bocchette
    dedicate.</p>
  <p>Inserisci le bottiglie spingendole a fondo nel raccoglitore, con l’imboccatura della bottiglia verso l’alto.</p>
  <p>Puoi farlo in <b>maniera anonima</b>, inserendo semplicemente i contenitori nelle bocchette. Sul monitor puoi
    verificare l’avanzamento del numero dei pezzi inseriti <b>(per ottenere lo sconto sarà necessario inserirne almeno
      30.
      Se non raggiungi 30 bottiglie non potrai stampare il coupon prescelto)</b></p>
  <p><b>OPPURE</b></p>
  <p>Se vuoi farlo in <b>maniera personalizzata</b>, tocca lo schermo, inserisci il tesserino sanitario o la carta
    d’identità elettronica nel verso indicato dall’adesivo e procedi all’inserimento nella modalità descritta.</p>
  <p><b>Potrai scegliere se stampare il coupon</b> (dedicato allo sconto da te scelto, scorrendo all’interno della
    lista
    dei
    partner con le frecce verdi) <b>oppure se accumulare i punti per un successivo utilizzo</b>, stampandolo una volta
    raggiunta una quantità di almeno 30.</p>
  <p>Potrai utilizzare i tuoi coupon per sconti e omaggi nelle attività che hanno aderito al progetto.</p>
  <p><b>Alcuni premi</b> (es. biglietti AMT e voucher MIMOTO) <b>sono richiedibili solo attraverso l’APP
      PlasTiPremia</b> e se sei stati identificato prima di iniziare il conferimento.</p>
  <p>Utilizzando la modalità personalizzata di conferimento e la APP PlasTiPremia si può verificare il totale dei
    conferimenti e quindi accedere alle premialità di secondo livello (ad esempio plaid, scaldacollo, frisbee e altri
    prodotti in plastica riciclata forniti da COREPLA) per i quali è richiesto un numero maggiore di punti.</p>
  <p>La macchina ha a disposizione anche una bocchetta per lasciare le borse utilizzate per il trasporto delle
    bottiglie.
    Per smaltire correttamente i dispositivi tecnologici, nella macchina ci sono anche 2 bocchette dedicate alla
    raccolta dei piccoli RAEE. Non danno punti, ma rendono un servizio utile ai cittadini.</p>
  <p>Nelle macchine si possono mettere solo i RAEE di piccole dimensioni, ad esempio: telefonini, carica batterie,
    auricolari, lettori mp3 e cuffiette, mouse, piccole calcolatrici, spazzolini da denti elettrici, piastre per
    capelli, rasoi elettrici, sveglie, orologi da polso o da tasca, computer per attività sportiva, immersione
    subacquee, corsa, canottaggio, apparecchiature sportive con componenti elettrici o elettronici (esempio
    cardiofrequenzimetro). <b>No lampadine, no pile.</b></p>
  <p><b>Il raccoglitore è videosorvegliato e non emette né contiene denaro.</b></p>

</div>

<div class="text-center mt-2 mb-2">
  <button class="btn" (click)="close()">Ho capito</button>
</div>