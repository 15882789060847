import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SignUpComponent} from './sign-up.component';
import {RegistrationFormComponent} from './registration-form/registration-form.component';
import {CtButtonModule} from '@ctsolution/ct-framework';
import {TitleHeadingModule} from 'src/app/components/title-heading/title-heading.module';
import {FormInputModule} from 'src/app/components/form-input/form-input.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatLegacyCheckboxModule as MatCheckboxModule} from '@angular/material/legacy-checkbox';
import {MatLegacyFormFieldModule as MatFormFieldModule} from '@angular/material/legacy-form-field';
import {MatLegacyInputModule as MatInputModule} from '@angular/material/legacy-input';
import {RouterModule} from '@angular/router';
import {RegistrationWelcomeModule} from "./registration-welcome/registration-welcome.module";
import { MatIconModule } from '@angular/material/icon';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';



@NgModule({
  imports: [
    CommonModule,
    TitleHeadingModule,
    CtButtonModule,
    MatCheckboxModule,
    FormInputModule,
    FormsModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    MatIconModule,
    RouterModule,
    NgbPopoverModule,
    RegistrationWelcomeModule,

  ],
  declarations: [SignUpComponent, RegistrationFormComponent],
  exports: [SignUpComponent, RegistrationFormComponent]
})
export class SignUpModule {
}
