import {Component, OnInit} from '@angular/core';

import {MissionsController} from 'src/app/core/controllers/missions-controller';
import {AuthService} from 'src/app/core/lib/auth/auth.service';
import {Missions} from "../../core/interfaces/missions";
import {SingleDataSet} from "ng2-charts";
import {ChartType} from 'chart.js';

@Component({
  selector: 'app-targets',
  templateUrl: './targets.component.html',
  styleUrls: ['./targets.component.scss'],
  providers: [MissionsController]
})
export class TargetsComponent implements OnInit {


  targets: Missions[] = [];
  userLevel: number = 0;

  public ChartType: ChartType = 'doughnut';

  public chartColors: Array<any> = [
    { // all colors in order
      backgroundColor: ['#f6aa1c', '#002484'],
      borderWidth: 1
    }
  ]

  public chartOptions = {
    responsive: true,
    maintainAspectRatio: false
  }

  private controller: string = 'account';

  constructor(
    private MissionsController: MissionsController,
    private AuthService: AuthService) {

    this.MissionsController.update().subscribe();

    this.MissionsController
      .get()
      .subscribe((missions: any) => {

        this.userLevel = this.AuthService.getLevel();

        this.targets = missions['result'];
        this.targets.forEach((target) => {
          if (target.completamento >= 99) target.completamento = 100
        })
      });

  }

  ngOnInit() {
  }

  getDonutDataSet(percent: number): SingleDataSet {

    let missing = 100 - percent;
    return [percent, missing];

  }

}


