import {AfterViewInit, ChangeDetectorRef, Component, Input, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {CtNotificationDialogService} from '@ctsolution/ct-notification-dialog';
import {CtButtonParameters} from '@ctsolution/ct-framework';
import {EcobinderTutorialComponent} from 'src/app/components/dialog/ecobinder-tutorial/ecobinder-tutorial.component';
import {BearerToken} from 'src/app/core/interfaces/bearer-token';
import {AppGeneralService} from 'src/app/core/lib/app-general.service';
import {AuthService} from 'src/app/core/lib/auth/auth.service';
import {Avatar} from 'src/app/core/lib/avatar/avatar';
import {AvatarService} from 'src/app/core/lib/avatar/avatar.service';
import {ActivatedRoute} from "@angular/router";
import {MailConfirmService} from "../../../core/lib/mail-confirm.service";
import {AccountController} from "../../../core/controllers/account-controller";
import {SnackbarService} from "../../../core/lib/snackbar.service";

@Component({
  selector: 'app-logged-home',
  templateUrl: './logged-home.component.html',
  styleUrls: ['./logged-home.component.scss']
})
export class LoggedHomeComponent implements OnInit {

  user: BearerToken;

  avatar?: Avatar;

  viewModel: any = {
    buttons: this.GetButtons()
  }

  scoreRedirect: CtButtonParameters = CtButtonParameters
    .create((event) => {
      this.appGeneralService.navigateTo('/saldo')
    })
    .setCSS('historical-score-link')
    .setLabel('Storico punti');

  constructor(
    public AppGeneralService: AppGeneralService,
    public authService: AuthService,
    private appGeneralService: AppGeneralService,
    private cdr: ChangeDetectorRef,
    private dialog: MatDialog,
    private avatarService: AvatarService,
    private ctNotificationDialogService: CtNotificationDialogService,
    private route: ActivatedRoute,
    private mailConfirmService: MailConfirmService,
    private accountController: AccountController,
    private snackbarService: SnackbarService
  ) {

    this.user = this.authService.getDecodedBearerToken();

    this.route
      .queryParams
      .subscribe(
        qp => {

          const mailConfirmedCookie = this.mailConfirmService.getMailConfirmedCookie();

          if (mailConfirmedCookie === 'true') return;

          const mailConfirmedParameter = qp['mail-confirmed'];

          if (mailConfirmedParameter === '1') {

            this.accountController
              .refreshClaims();

            const snackbar = this.snackbarService.openGenericSnackBar('Grazie per aver confermato la tua e-mail!');
            snackbar.afterOpened()
              .subscribe(() => this.mailConfirmService.setMailConfirmedCookie());

          }

        });

  }

  // ngAfterViewInit(): void {

  //   this.viewModel.buttons.setInfoTemplate(this.iconRightTemplate);

  //   this.cdr.detectChanges();

  //   //TODO Marghe: aggiungere nel pacchetto CTBUTTON - lascio commentato come trick per <ng-template> in ctButton per modifiche custom del template

  // }

  ngOnInit() {

    this.avatarService
      .getUserAvatar()
      .then((result: Avatar) => this.avatar = result);

    this.ctNotificationDialogService.checkLastNotification();

  }

  openEcoTutorialDialog() {

    this.dialog.open(EcobinderTutorialComponent, {
      maxWidth: '365px',
      maxHeight: '70vh',
      panelClass: 'eco21-dialog'
    });

  }

  GetButtons(): Array<CtButtonParameters> {

    return [
      CtButtonParameters
        .create((event) => {
          this.appGeneralService.navigateTo('/compattatori')
        })
        .setCSS("search-button", true)
        .setImage('/assets/images/reference-images/compattatore.png')
        .setImageCss('reference-image')
        .setContent('Trova il raccoglitore <br> vicino a te'),
      
       
     
      CtButtonParameters
        .create((event) => {
          this.appGeneralService.navigateTo('/partners')
        })
        .setCSS("search-button", true)
        .setImage('/assets/images/reference-images/shop.svg')
        .setImageCss('reference-image')
        .setContent('Scegli come usare <br> i tuoi punti')
       

    ];

  }

}


