import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormInputParameters } from './class/form-input-parameters';

@Component({
  selector: 'form-input',
  templateUrl: './form-input.component.html',
  styleUrls: ['./form-input.component.scss']
})
export class FormInputComponent implements OnInit {

  @Input() parameters!: FormInputParameters;

  @Output() change: EventEmitter<any> = new EventEmitter<any>();
  @Output() blur: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {

  }

  checkMatErrorGeneric():boolean{
    var check = this.parameters.formControl.errors != null && this.parameters?.formInputValidations.length > 0;
    return check;
  }

  getErrorMessage():string{
    let errorMessages: Array<string> = [];
    if(this.parameters.formInputValidations){
      this.parameters.formInputValidations.forEach(formInputValidation => {
        if(this.parameters.formControl.errors && this.parameters.formControl.errors[formInputValidation.name] != undefined)
            errorMessages.push(formInputValidation.errorMessageKey);
      });
    }
    return errorMessages.join("<br>");
  }
}



