import {Component, OnInit} from '@angular/core';
import {BindersResearchResults} from "../../../core/interfaces/binders-research-results";
import {DateService} from "../../../core/lib/date.service";
import {FormBuilder, UntypedFormControl, UntypedFormGroup} from "@angular/forms";
import {ILatLng, MapsService} from "../../../core/lib/maps.service";
import {BindersController} from "../../../core/controllers/binders-controller";
import {BINDER_TYPE} from "../../../core/enums/binder-type.enum";
import {DAY} from "../../../core/enums/day";

@Component({
  selector: 'app-binder-news-list',
  templateUrl: './binder-news-list.component.html',
  styleUrls: ['./binder-news-list.component.scss'],
  providers: [BindersController]
})
export class BinderNewsListComponent implements OnInit {

  form: UntypedFormGroup;
  ecovans: Array<BindersResearchResults> = [];
  Days = DAY;

  constructor(
    private dateService: DateService,
    private fb: FormBuilder,
    private maps: MapsService,
    private bindersController: BindersController
  ) {

    let dayNum = this.dateService.getCurrentDay();

    if (dayNum === 0) {
      dayNum = 7;
    }

    this.form = this.fb.group({
      day: new UntypedFormControl(dayNum)
    });

    this.research();

  }

  ngOnInit() {
  }

  research() {

    this.ecovans = [];
    const DayValue = this.form.value.day;

    let coords: ILatLng = this.maps.getLastSearchedLocation();

    this.bindersController.research(
      BINDER_TYPE.ECOVAN,
      coords.latitude,
      coords.longitude,
      DayValue === 7 ? null : DayValue
    ).subscribe((result: any) => {

      this.ecovans = result['result'];
      this.ecovans.forEach((ecovan: any) => {
        ecovan.mediumimmagine = '/assets/images/ecovan.png';
      });

    });

  }

}
