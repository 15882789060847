import {NgModule} from '@angular/core';
import {SignInModule} from "./sign-in/sign-in.module";
import {SignUpModule} from "./sign-up/sign-up.module";
import {RecoveryPasswordModule} from "./recovery-password/recovery-password.module";
import {ResetPasswordModule} from "./reset-password/reset-password.module";

@NgModule({
  imports: [
    SignInModule,
    SignUpModule,
    RecoveryPasswordModule,
    ResetPasswordModule
  ]
})
export class AuthenticationModule {
}
