import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {MatBottomSheet} from '@angular/material/bottom-sheet';

import {EcoCouponData} from 'src/app/core/classes/eco-coupon-data';
import {CouponController} from 'src/app/core/controllers/coupon-controller';
import {DisponibilitaController} from 'src/app/core/controllers/disponibilita-controller';
import {PartnersCouponController} from 'src/app/core/controllers/partner-coupon-controller';
import {DisponibilitaCouponDTO} from 'src/app/core/interfaces/disponibilita-coupon';
import {ICoupon} from 'src/app/core/interfaces/eco-coupon';
import {AppGeneralService} from 'src/app/core/lib/app-general.service';
import {DateService} from 'src/app/core/lib/date.service';
import {SnackbarService} from 'src/app/core/lib/snackbar.service';
import {EcoCouponRequestComponent} from './eco-coupon-request/eco-coupon-request.component';

@Component({
  selector: 'app-eco-coupon-list',
  templateUrl: './eco-coupon-list.component.html',
  styleUrls: ['./eco-coupon-list.component.scss']
})
export class EcoCouponListComponent implements OnInit {


  ecoCouponList!: Array<EcoCouponData>

  @ViewChild('scroller') private scroll_cntr: ElementRef | undefined;

  affiliate!: ICoupon;
  dateControl: UntypedFormControl = new UntypedFormControl(null);
  coupon_list: EcoCouponData[] = [];
  balance: number = 0;
  availability: DisponibilitaCouponDTO[] = [];

  viewModel = {

    isEvent: true

  };

  dateFilter = (date: Date | null): boolean => {
    return false;
  };

  constructor(
    public appGeneralService: AppGeneralService,
    private partnerCouponController: PartnersCouponController,
    private _bottomSheet: MatBottomSheet,
    private couponController: CouponController,
    private snackbarService: SnackbarService,
    private dateService: DateService,
    private disponibilitaController: DisponibilitaController
  ) {
    this.partnerCouponController
      .getHistory()
      .subscribe((result: any) => {
        var coupons = result.result as Array<EcoCouponData>;
        this.ecoCouponList = coupons.filter(coupon => coupon.despatchedAt == null);
      });
  }

  ngOnInit() {
  }


  getDetail() {

    this.couponController
      .getCoupons().subscribe((result) => {

      const today = new Date();
      today.setHours(0, 0, 0, 0);

      const results = result['result']
        .filter((coupon: any) => {
          return new Date(this.dateService.convertCustomDate(coupon['dataScadenza'].split(' ')[0])) >= today;
        });

      this.coupon_list = results.filter((coupon: any) => coupon.tipoCoupon === this.affiliate!.type);

    });

  }

  request(couponId: number, partnerName: string) {

    if (this.viewModel.isEvent) {

      this._bottomSheet.open(EcoCouponRequestComponent, {data: {couponId: couponId, partnerName: partnerName}});

    } else {

      this.getCoupon();

    }

  }

  getCoupon() {

    this.couponController
      .requestCoupon(this.affiliate!.type!, this.dateControl.value).subscribe(
      (result: any) => {

        this.getDetail();

        this.snackbarService.openGenericSnackBar(result['message']);

        this.balance -= this.affiliate!.value;

        if (this.viewModel.isEvent) {

          this.getAvailabilities();

        }

      });

  }

  getAvailabilities() {

    this.disponibilitaController
      .getAvailability(this.affiliate?.type!)
      .subscribe((r: any) => {

        if (r?.result) {

          this.availability = r.result as DisponibilitaCouponDTO[];

        }

        const availableDates: number[] = this.availability.map(a => {

          let date = new Date(a.startDate);
          date.setHours(0, 0, 0, 0);
          return date.getTime();

        });

        this.dateControl.setValue(new Date(availableDates[0]));

        this.dateFilter = (date: Date | null): boolean => {
          return date ? availableDates.includes(date.getTime()) : false;
        };

      });

  }


  scrollToBottom(): void {

    setTimeout(() => {

      try {
        this.scroll_cntr!.nativeElement.scrollTop = this.scroll_cntr!.nativeElement.scrollHeight;

      } catch (err) {
      }

    }, 0);


  }

  formatDate = (date: string) => this.appGeneralService.toShortDate(date);


}
