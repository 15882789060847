import {Component, Inject, OnInit} from '@angular/core';
import {MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA} from '@angular/material/bottom-sheet';
import {RendeemData} from 'src/app/core/classes/rendeem-data';
import {PartnersCouponController} from 'src/app/core/controllers/partner-coupon-controller';
import {AppGeneralService} from 'src/app/core/lib/app-general.service';


@Component({
  selector: 'app-eco-coupon-request',
  templateUrl: './eco-coupon-request.component.html',
  styleUrls: ['./eco-coupon-request.component.scss']
})
export class EcoCouponRequestComponent implements OnInit {

  couponId: number = 0;

  constructor(
    private _snackBar: MatBottomSheetRef<EcoCouponRequestComponent>,
    private partnersCouponController: PartnersCouponController,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any
  ) {
  }

  ngOnInit() {
  }


  redeem() {

    const data: RendeemData = {

      CouponId: this.data.couponId

    }

    this.partnersCouponController
      .put(data).subscribe(() => {

      this._snackBar.dismiss();
      this.refresh();


    });

  }

  refresh(): void {

    window.location.reload();

  }

  cancel() {

    this._snackBar.dismiss();

  }

}


