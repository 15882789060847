import {Component, OnInit} from '@angular/core';
import {AppGeneralService} from 'src/app/core/lib/app-general.service';
import {AuthService} from 'src/app/core/lib/auth/auth.service';
import {Avatar} from 'src/app/core/lib/avatar/avatar';
import {AvatarService} from 'src/app/core/lib/avatar/avatar.service';
import {SnackbarService} from "../../../core/lib/snackbar.service";

@Component({
  selector: 'app-avatars',
  templateUrl: './avatars.component.html',
  styleUrls: ['./avatars.component.scss']
})
export class AvatarsComponent implements OnInit {

  avatars: Array<Avatar> = [];
  userLevel: number = 0;

  constructor(
    private appGeneralService: AppGeneralService,
    private authService: AuthService,
    private avatarService: AvatarService,
    private snackbarService: SnackbarService
  ) {

    this.userLevel = this.authService.getLevel();
    this.avatars = this.avatarService.Avatars.sort((a, b) => (a.level > b.level) ? 1 : -1);

  }

  ngOnInit() {
  }

  changeAvatar(avatar: Avatar) {

    if (avatar.level <= this.userLevel) {

      this.avatarService
        .putUserAvatar(avatar.code)
        .then(() => {

          this.snackbarService.openGenericSnackBar('Avatar aggiornato!')
          this.appGeneralService.back();

        });

    }

  }

  getAvatarPathImage(avatar: Avatar): string {

    let path: string = avatar.getFullPath();

    // if (avatar.level > this.userLevel) {


    //   return path.replace('.png', '_off.png');

    // }

    return path;

  }

}
