<div class="spinnerCntr h-100 w-100" [hidden]="!isSpinnerVisible">
  <div class="h-100 w-100 d-flex">
    <div class="loadingio-spinner-disk-y3r0m3d56i8 m-auto">
      <div class="ldio-4mdrg3wv2sf">
        <div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  </div>
</div>
