<div class="pt-4 title-heading-cntr">

  <ng-container *ngIf="titleHeadingViewModel">

    <ng-container *ngIf="!viewModel.isBackButton">
      
    <app-back-button></app-back-button>
  
    </ng-container>
  
    <div class="title-heading-cntreco-21-cntr" [class]="titleHeadingViewModel.class">
  
      <h1 class="text-center primary-color">{{titleHeadingViewModel.title}}</h1>
  
      <p class="text-center font-weight-bolder" [class]="titleHeadingViewModel.classSubtitle" *ngIf="titleHeadingViewModel.subtitle">{{titleHeadingViewModel.subtitle}}</p>
  
    </div>
  
  </ng-container>

</div>


