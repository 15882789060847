import {Component, OnInit} from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn} from "@angular/forms";
import {CtButtonParameters} from "@ctsolution/ct-framework";
import {ActivatedRoute} from "@angular/router";
import {PasswordStrengthValidator} from "../password-strength.validator";
import {PasswordReset} from "../../core/classes/password-reset";
import {PasswordController} from "../../core/controllers/password-controller";
import {SnackbarService} from "../../core/lib/snackbar.service";
import {AppGeneralService} from "../../core/lib/app-general.service";
import { FormInputParameters } from 'src/app/components/form-input/class/form-input-parameters';
import { FormInputValidationStandard, FormInputValidationValue } from 'src/app/components/form-input/class/form-input-validation';

export function CTMatchFieldValidator(controllerMatching1: UntypedFormControl, controllerMatching2: UntypedFormControl): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    return controllerMatching1.value === controllerMatching2.value ? null : {CTMatchFieldValidator: true};
  };
}
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
  providers: [PasswordController]
})
export class ResetPasswordComponent implements OnInit {

  form: UntypedFormGroup = new UntypedFormGroup({});
  successMessage: string = "Il reset della tua password è avvenuto con successo!"
  resetToken?: string;

  email: FormInputParameters = FormInputParameters
  .create('email',this.form)
  .setLabel('Inserisci la tua mail')
  .setPlaceHolder('francesco@ecoxxi.it')
  .addValidator(FormInputValidationStandard.Required())
  .addValidator(FormInputValidationStandard.MinLength(6));

  passwordNew: FormInputParameters = FormInputParameters
  .create('passwordNew', this.form)
  .setLabel('Inserisci la nuova password')
  .setHint('*La password deve essere composta da almeno 6 caratteri, contenere una lettera maiuscola, una minuscola e un numero')
  .addValidator(FormInputValidationStandard.Required())
  .addValidator(FormInputValidationStandard.MinLength(6))
  .addValidator(FormInputValidationValue.Create("passwordStrength",PasswordStrengthValidator,"Deve contenere una lettera maiuscola,una lettera minuscola e un numero"));

  passwordConfirm: FormInputParameters = FormInputParameters
  .create('passwordConfirm', this.form)
  .setLabel('Conferma la nuova password')
  .addValidator(FormInputValidationStandard.Required())


  sendButton: CtButtonParameters = CtButtonParameters
    .create((event) => this.update())
    .setCSS("general-button", true)
    .setContent("Salva")

  constructor(
    private route: ActivatedRoute,
    private passwordController: PasswordController,
    private snackbarService: SnackbarService,
    private appGeneralService: AppGeneralService
  ) {

    this.route
      .queryParams
      .subscribe(qp => {

        if (qp['token']) {
          this.resetToken = qp['token']
        }

      });

      this.form.addValidators(CTMatchFieldValidator(this.passwordNew.formControl, this.passwordConfirm.formControl));

  }


  ngOnInit() {
  }

  onMatchErrorFormGroup():boolean{
    return this.form.errors && this.form.errors['CTMatchFieldValidator'];
  }

  update() {

    if (this.form.valid && this.resetToken) {

      const PasswordResetParam = new PasswordReset(
        this.resetToken,
        this.form.value.email,
        this.form.value.passwordNew
      )
      this.passwordController
        .reset(PasswordResetParam).subscribe(() => {

        this.snackbarService.openGenericSnackBar(this.successMessage);
        this.appGeneralService.navigateTo('/');

      });

    }

  }


  // TODO: migrare il funzionamento del matcher da PlasTiPremia e integrarlo correttamente nel component form-input (BUG 3512)
  checkPasswords(group: UntypedFormGroup) { // here we have the 'passwords' group

    let pass = group.get('passwordNew')?.value;
    let confirmPass = group.get('passwordConfirm')?.value;

    return pass === confirmPass ? null : {notSame: true}

  }

}
