import { Component, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { CtButtonParameters } from '@ctsolution/ct-framework';
import { AccountController } from 'src/app/core/controllers/account-controller';
import { AuthService } from 'src/app/core/lib/auth/auth.service';
import { ICallResult } from 'src/app/core/interfaces/Icall-result';
import { CallResult } from 'src/app/core/classes/call-result';


@Component({
  selector: 'app-delete-profile',
  templateUrl: './delete-profile.component.html',
  styleUrls: ['./delete-profile.component.scss']
})
export class DeleteProfileComponent implements OnInit {

  deleteButton = CtButtonParameters
  .create((event) => {this.deleteProfile()})
  .setCSS("worning-button", true)

  .setContent("Elimina Profilo")


  annulla = CtButtonParameters
  .create((event) => { this.close()})
  .setCSS("general-button", true)
  
  .setContent("Annulla")

  constructor(

    private accountController: AccountController,
    private authService: AuthService,
    private _snackbar: MatSnackBar,
    public dialogRef: MatDialogRef<DeleteProfileComponent>

  ) { }

  ngOnInit() {
  }

  close() {

    this.dialogRef.close();

  }

  deleteProfile(){
   
    this.accountController
    .delete()
    .then((result: ICallResult) => {

      this._snackbar.open(result.result, 'X', {
        duration: 5000

     });

     this.authService.deleteBearerTKN();

    });

    this.dialogRef.close();

  }

}
