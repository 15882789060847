<div class="conferments-list-cntr">

  <div *ngFor="let conferimento of conferimenti">

    <div class="font-weight-bolder dark-green" [innerHTML]="conferimento.dataConferimento"></div>

    <div class="conferment-message mb-3 mt-1 font-weight-light" [innerHTML]="conferimento.messaggio"></div>

  </div>

</div>