import {CallResult} from './../classes/call-result';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {CtWebapiService, DataRequest} from '@ctsolution/ct-webapi';

@Injectable()

export class BalanceController {

  private controller: string = 'conferimento';

  constructor(private ctWebApiService: CtWebapiService) {
  }

  get(): Observable<CallResult> {

    const request: DataRequest = new DataRequest(this.controller, { hideSpinner: true });
    return this.ctWebApiService.get(request);

  }

  getHistory(): Observable<CallResult> {

    const request: DataRequest = new DataRequest(`${this.controller}/lista`);
    return this.ctWebApiService.get(request);

  }

  post(transactionCode: string): Observable<CallResult> {

    const request: DataRequest = new DataRequest(`${this.controller}/${transactionCode}`);
    return this.ctWebApiService.post(request, {});

  }

}
