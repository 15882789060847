import {CallResult} from './../classes/call-result';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {CtWebapiService, DataRequest} from '@ctsolution/ct-webapi';

@Injectable()
export class AvatarController {

  private controller: string = 'avatar';

  constructor(

    private ctWebApiService: CtWebapiService
    
    ) {
  }

  get(): Observable<CallResult> {

    const request: DataRequest = new DataRequest(this.controller, 'Get');
    return this.ctWebApiService.get(request);

  }

  update(avatarCode: number): Observable<CallResult> {

    const request: DataRequest = new DataRequest(this.controller, 'Update');
    return this.ctWebApiService.put(request, avatarCode);

  }

}
