import {Component, OnInit} from '@angular/core';
import {CtButtonParameters} from '@ctsolution/ct-framework';
import {AppGeneralService} from 'src/app/core/lib/app-general.service';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {

  viewModel: any = {

    buttons: this.GetButtons(),

    beginPartner: CtButtonParameters
      .create((event) => {
      })
      .setCSS("general-button", true)
      .setContent("Diventa partner"),

  }

  constructor(private appGeneralService: AppGeneralService) {
  }

  ngOnInit() {
  }

  GetButtons(): Array<CtButtonParameters> {
    return [
      CtButtonParameters
        .create((event) => {
          this.appGeneralService.navigateTo('/registrazione')
        })
        .setCSS("general-button", true)
        .setContent("Registrati"),

      CtButtonParameters
        .create((event) => {
          this.appGeneralService.navigateTo('/accesso/login', {queryParamsHandling: "merge"})
        })
        .setCSS("general-button", true)
        .setContent("Accedi"),
    ];
  }

}
