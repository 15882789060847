<div class="reset-password-cntr">

  <div class="container h-100">

    <title-heading [titleHeadingViewModel]="{ title: 'Recupera Password'}"></title-heading>

     <form [formGroup]="form">

      <div class="container">

        
      <form-input [parameters]="email"></form-input>

      <div class="mb-4">

        <form-input [parameters]="passwordNew"></form-input>

      </div>

      <form-input [parameters]="passwordConfirm"></form-input>
      <mat-error *ngIf="onMatchErrorFormGroup()">Le password non coincidono</mat-error>


      </div>

      <div class="mt-4 m-auto text-center">

        <ct-button [parameters]="sendButton"></ct-button>

      </div>

    </form> 

  </div>

</div>
