<div class="container user-card-cntr h-100">

  <mat-card class="mb-2">

    <div class="row justify-content-between w-100">

      <div class="col-2">

        <div mat-card-title>
          {{position}}</div>

      </div>
      <div class="col-3">

        <div mat-card-avatar *ngIf="avatar">
          <img [src]="avatar.getFullPath()" class="user-card-avatar">
        </div>

      </div>

      <div class="col-7">

        <div mat-card-title>
          {{user.userName}}
        </div>

        <hr>

        <div class="d-flex w-75 justify-content-between">

          <div>

            <p><strong>Livello</strong></p>

            <div mat-card-subtitle>{{ user.level}}
            </div>

          </div>

          <div>

            <p><strong>Punti</strong></p>

            <div mat-card-subtitle>{{ user.userPoints}}
            </div>

          </div>

        </div>

      </div>

    </div>

  </mat-card>

</div>