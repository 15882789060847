<div class="eco-21-cntr compactors-cntr">

  <title-heading [titleHeadingViewModel]="{ title: 'Raccoglitori'}"></title-heading>

  <div class="container h-100">

    <div class="news-research-cntr pl-5 pr-5">

      <div>

        <app-google-input-autocomplete
          [configuration]="googleInputAutoCompleteConfiguration"
          (onLocationChange)="research($event.latitude, $event.longitude)"></app-google-input-autocomplete>

      </div>

    </div>

    <ng-container *ngIf="compactors.length > 0; else emptyTemplate">

      <ng-container *ngFor="let compactor of compactors">

        <div class="card-border b-radius mt-4">

          <app-card [card]="compactor"></app-card>

        </div>

      </ng-container>

    </ng-container>
    <ng-template #emptyTemplate>

      <p class="text-center"> Non ci sono raccoglitori disponibili. </p>

    </ng-template>

  </div>

</div>
