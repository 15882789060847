import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ConfermentsListComponent} from './conferments-list.component';

@NgModule({
  declarations: [ConfermentsListComponent],
  imports: [CommonModule],
  exports: [ConfermentsListComponent]
})
export class ConfermentsListModule {
}
