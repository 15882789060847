import {BearerToken} from '../../interfaces/bearer-token';
import {AppGeneralService} from '../app-general.service';
import {Injectable} from '@angular/core';
// @ts-ignore
import * as jwt_decode from 'jwt-decode';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private readonly bearerCookie = 'PNDTKN';

  constructor(
    private AppGeneralService: AppGeneralService,
    private router: Router) {
  }

  getDecodedAccessToken(token: string): any {

    try {

      return jwt_decode(token);

    } catch (Error) {

      return null;

    }

  }

  setBearerToken = (Bearer: string): void => localStorage.setItem(this.bearerCookie, Bearer);

  getBearerToken = (): string | null => localStorage.getItem(this.bearerCookie);

  getDecodedBearerToken = (): BearerToken => this.getDecodedAccessToken(this.getBearerToken() ?? '');

  bearerInexistent = (): boolean => this.AppGeneralService.isEmpty(this.getDecodedBearerToken());

  bearerExpired(): boolean {

    const BearerToken: BearerToken = this.getDecodedBearerToken();
    return !this.bearerInexistent() && new Date(BearerToken.exp * 1000) < new Date();

  }

  getTariCodeConfirmed(): boolean {

    const BearerToken: BearerToken = this.getDecodedBearerToken();
    return JSON.parse(BearerToken.CodiceTariNotifica.toLowerCase());

  }

  getEmailConfirmed(): boolean {

    const BearerToken: BearerToken = this.getDecodedBearerToken();
    return JSON.parse(BearerToken.EmailConfirmed.toLowerCase());

  }

  getUserEmail = (): string => this.getDecodedBearerToken().Email;

  getLevel(): number {

    const lvl: number = +this.getDecodedBearerToken().Livello;
    return lvl ?? 1;

  }

  deleteBearerTKN(): void {

    localStorage.removeItem(this.bearerCookie);
    setTimeout(() => this.router.navigate(['/accedi']), 0);

  }

}
