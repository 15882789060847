import {Component, OnInit} from '@angular/core';
import {MailConfirmService} from "../../../core/lib/mail-confirm.service";

@Component({
  selector: 'app-registration-welcome',
  templateUrl: './registration-welcome.component.html',
  styleUrls: ['./registration-welcome.component.scss']
})
export class RegistrationWelcomeComponent implements OnInit {

  constructor(private mailConfirmService: MailConfirmService) {
  }

  ngOnInit() {

    this.mailConfirmService.deleteMailConfirmedCookie();

  }

}
