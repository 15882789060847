import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CtButtonParameters } from '@ctsolution/ct-framework';
import { PasswordStrengthValidator } from 'src/app/authentication/password-strength.validator';
import { FormInputParameters } from 'src/app/components/form-input/class/form-input-parameters';
import { FormInputValidationStandard, FormInputValidationValue } from 'src/app/components/form-input/class/form-input-validation';
import { RegisterFamiliarData } from 'src/app/core/classes/register-familiar-data';
import { FamiliarController } from 'src/app/core/controllers/familiar-controller';
import { AppGeneralService } from 'src/app/core/lib/app-general.service';

@Component({
  selector: 'app-familiar-registration-complete',
  templateUrl: './familiar-registration-complete.component.html',
  styleUrls: ['./familiar-registration-complete.component.scss']
})
export class FamiliarRegistrationCompleteComponent implements OnInit {

  queryStringToken: string = '';
  querystringEmail: string = '';

  form: UntypedFormGroup = new UntypedFormGroup({});

  nickname: FormInputParameters = FormInputParameters
  .create('nickname',this.form)
  .setLabel('Nickname')
  .addValidator(FormInputValidationStandard.Required())
  .addValidator(FormInputValidationStandard.MinLength(5))
  .addValidator(FormInputValidationStandard.MaxLength(20))
  .addValidator(FormInputValidationValue.Create("pattern",Validators.pattern(/^\S*$/),"Il Nickname non può contenere caratteri speciali"))
  .setHint('Non sono consentiti spazi, due punti, virgolette o caratteri speciali. Può comprendere un massimo di 20 caratteri e un minimo di 5');

  
  email: FormInputParameters = FormInputParameters
  .create('email',this.form)
  .setLabel('Email')
  .addValidator(FormInputValidationValue.Create("email",Validators.email,"La mail non è valida"))
 


  password: FormInputParameters = FormInputParameters
  .create('password',this.form)
  .setLabel('Password')  
  .addValidator(FormInputValidationStandard.Required())
  .addValidator(FormInputValidationStandard.MinLength(6))
  .addValidator(FormInputValidationValue.Create("passwordStrength",PasswordStrengthValidator,"La password non può contenere caratteri speciali"))
  .setHint('Deve essere composta da almeno 6 caratteri, contenere almeno una lettera minuscola, una lettera maiuscola e un numero');

  femaleBounce: boolean = false;
  maleBounce: boolean = false;
  selectedGender: number = 1;

  
  registerButton: CtButtonParameters = CtButtonParameters
    .create((event) => this.register())
    .setCSS("general-button", true)
    .setContent("Registrati")


  constructor(
 
    public appGeneralService: AppGeneralService,
    private route: ActivatedRoute,
    private familiarController: FamiliarController
  ) { 
    
    const queryParam: any = this.route.snapshot.queryParamMap

    this.queryStringToken = queryParam.get('token');

    this.querystringEmail = queryParam.get('email');

  }

  ngOnInit() {
  }

  ngAfterViewInit(){
    this.email.setValue(this.querystringEmail);
  }


  register() {

 
    if (this.form.valid) {

      const RegisterFamiliar = new RegisterFamiliarData(
        this.selectedGender,
        this.form.get('nickname')?.value,
        this.form.get('password')?.value,
        this.querystringEmail,
        this.queryStringToken
      );

      this.familiarController.registerFamiliar(RegisterFamiliar);
    }
  }

}
