import {Injectable} from '@angular/core';
import {Location} from '@angular/common';
import {NavigationExtras, Router} from '@angular/router';
import {MatLegacyDialog as MatDialog} from "@angular/material/legacy-dialog";
import {CookiesComponent} from "../../pages/settings/cookies/cookies.component";

@Injectable({
  providedIn: 'root'
})

export class AppGeneralService {

  constructor(
    private location: Location,
    private router: Router,
    public dialog: MatDialog
  ) {
  }

  back = () => this.location.back();

  navigateTo(url: string, extras?: NavigationExtras) {

    this.router.navigate([url], extras)

  }

  isEmpty = (obj: any): boolean => obj === undefined || obj === null;

  openCookieModal = () => this.dialog.open(CookiesComponent, {maxHeight: '80vh', maxWidth: '500px'});

  toShortDate = (date:string) => new Date(date).toLocaleDateString();

}
