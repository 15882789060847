import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MailConfirmService {


  private readonly mailConfirmedCookie = 'mailConfirmed';


  constructor() {
  }

  setMailConfirmedCookie = (): void => sessionStorage.setItem(this.mailConfirmedCookie, 'true');

  deleteMailConfirmedCookie = (): void => sessionStorage.removeItem(this.mailConfirmedCookie);

  getMailConfirmedCookie = (): any => sessionStorage.getItem(this.mailConfirmedCookie);

}
