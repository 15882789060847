import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule, routes} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {RouterModule} from '@angular/router';
import {PagesModule} from './pages/pages.module';
import {LayoutModule} from './layout/layout.module';
import {CtWebapiModule} from "@ctsolution/ct-webapi";
import {environment} from "../environments/environment";
import {CtWebviewModule} from "@ctsolution/ct-webview";
import {AuthenticationInterceptor} from "./core/lib/authentication.interceptor";
import {AgmCoreModule} from '@agm/core';
import {AuthenticationModule} from "./authentication/authentication.module";
import {MatLegacySnackBarModule as MatSnackBarModule} from "@angular/material/legacy-snack-bar";
import {SpinnerComponent} from "./components/spinner/spinner.component";
import {CtNotificationDialogModule} from '@ctsolution/ct-notification-dialog';
import {APPLICATION_CONFIG} from "../environments/environment.config";
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {CtFrameworkModule, HttpLoaderFactory} from "@ctsolution/ct-framework";

@NgModule({
  declarations: [
    AppComponent,
    SpinnerComponent

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgbModule,
    HttpClientModule,
    RouterModule.forRoot(routes),
    CtFrameworkModule.forRoot(),
    AuthenticationModule,
    PagesModule,
    LayoutModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAqKwjGo4ujpkh_0szfx-FVqxON8FBAHHc',
      libraries: ['places']
    }),
    CtWebapiModule.setup(environment.API_URL),
    CtWebviewModule.setup(APPLICATION_CONFIG),
    CtNotificationDialogModule.setup(environment.Project.Name),
    MatSnackBarModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthenticationInterceptor,
      multi: true
    }],
  bootstrap: [AppComponent]
})
export class AppModule {
}
