<div class="eco-21-cntr partner-cntr h-100">

  <title-heading [titleHeadingViewModel]="{ title: 'Partner'}"></title-heading>

  <div class="text-center">

    <mat-icon class="light-green coupon-partner">local_activity</mat-icon>

  </div>

  <ng-container *ngIf="partner">

    <div class="container mt-4 h-100">

      <div class="text-center">

        <p class="dark-green font-weight-bolder" style="font-size: 18px">{{partner.nome}}</p>

        <p class="text-uppercase light-green">{{partner.indirizzo}}</p>

      </div>

      <div class="mt-4">

        <div *ngFor="let product of partner.prodotti">

          <p class="text-center primary-color font-weight-bolder text-uppercase" style="font-size: 16px">

            VALORE ACQUISTO {{product.punti}} PUNTI

          </p>

          <div class="separetor"></div>

          <div class="sale-cntr mb-2 primary-color b-radius text-center mt-3 p-2">
          
              <h2 class="text-center">{{product.nome}}</h2>  

            <p>{{product.descrizione}}</p>

          </div>

          <ng-container *ngIf="balance == product.punti || balance > product.punti; else noBalanceTmpl">

            <div class="text-center mt-5 mb-5">

              <ct-button [parameters]="generateParameters(product)"></ct-button>

            </div>

          </ng-container>

          <ng-template #noBalanceTmpl>

            <div class="text-center mt-5">

              <button class="general-button">Riscatta</button>

              <div class="mt-3 mb-5">
                
              <small class="mt-5 text-decoration-underline orange-color">Ops! Non hai sufficienti punti per
                riscattare il coupon 😔</small>

              </div>


            </div>

          </ng-template>

        </div>

      </div>

    </div>

  </ng-container>

</div>