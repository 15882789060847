<div class="eco-21-cntr news-ecovans-cntr">

  <title-heading [titleHeadingViewModel]="{ title: 'News', subtitle: 'Trova un EcoVan'}"></title-heading>

  <div class="container">

    <form class="news-research-cntr pl-5 pr-5" [formGroup]="form">

      <!--TODO: manca implementazione gestione tendina di ricerca in base al giorno -->
      <!--      <app-select-option-->
      <!--        [selectOptionViewModel]="{control: form.get('day'), label: 'Giorno settimanale', types: }"-->
      <!--        (change)="research()"></app-select-option>-->

    </form>

    <div class="row">

      <ng-container *ngIf="ecovans.length > 0; else emptyTemplate">

        <ng-container *ngFor="let ecovan of ecovans">

          <div class="col-12 col-lg-7 m-auto mt-4 card-border b-radius">

            <app-card [card]="ecovan"></app-card>

          </div>

        </ng-container>

      </ng-container>

      <ng-template #emptyTemplate>

        <p class="text-center"> Non ci sono ecovan disponibili. </p>

      </ng-template>

    </div>

  </div>

</div>
