import { UntypedFormControl, UntypedFormGroup, ValidatorFn } from "@angular/forms";
import { ErrorStateMatcher } from "@angular/material/core";
import { FormInputValidationValue } from './form-input-validation';

export class FormInputParameters {

  private _formGroup!: UntypedFormGroup;
  public get formGroup(): UntypedFormGroup {
    return this._formGroup;
  }
  public set formGroup(value: UntypedFormGroup) {
    this._formGroup = value;
  }

  private _cssStyleHint?: string | undefined;
  public get cssStyleHint(): string | undefined {
    return this._cssStyleHint;
  }
  public set cssStyleHint(value: string | undefined) {
    this._cssStyleHint = value;
  }

  private _label: string | undefined;
  public get label(): string | undefined {
    return this._label;
  }
  private set label(value: string | undefined) {
    this._label = value;
  }

  private _type: any | undefined;
  public get type(): any | undefined {
    return this._type;
  }
  private set type(value: any | undefined) {
    this._type = value;
  }

  private _pattern: string | undefined;
  public get pattern(): string | undefined {
    return this._pattern;
  }
  private set pattern(value: string | undefined) {
    this._pattern = value;
  }

  private _placeholder: string | undefined;
  public get placeholder(): string | undefined {
    return this._placeholder;
  }
  private set placeholder(value: string | undefined) {
    this._placeholder = value;
  }

  private _formInputValidations!: Array<FormInputValidationValue>;
  public get formInputValidations(): Array<FormInputValidationValue> {
    return this._formInputValidations;
  }
  public set formInputValidations(value: Array<FormInputValidationValue>) {
    this._formInputValidations = value;
  }

  private _hint?: string | undefined;
  public get hint(): string | undefined {
    return this._hint;
  }
  private set hint(value: string | undefined) {
    this._hint = value;
  }

  private _controlName!: string;
  public get controlName(): string {
    return this._controlName;
  }
  private set controlName(value: string) {
    this._controlName = value;
  }


  private _readonly?: boolean | undefined;
  public get readonly(): boolean | undefined {
    return this._readonly;
  }
  private set readonly(value: boolean | undefined) {
    this._readonly = value;
  }

  private _isRequired!: boolean;
  public get isRequired(): boolean {
    return this._isRequired;
  }
  public set isRequired(value: boolean) {
    this._isRequired = value;
  }
  

  private _cssStyleInput?: string | undefined;
  public get cssStyleInput(): string | undefined {
    return this._cssStyleInput;
  }
  public set cssStyleInput(value: string | undefined) {
    this._cssStyleInput = value;
  }

  private _formControl: UntypedFormControl;

  public get formControl(): UntypedFormControl {
    return this._formControl;

  }
  public set formControl(value: UntypedFormControl) {
    this._formControl = value;
  }

  private constructor(controllerName: string, formGroup: UntypedFormGroup) {
    this.controlName = controllerName;
    this.formGroup = formGroup;
    this._formControl = new UntypedFormControl('');
    this.formGroup.addControl(this.controlName, this.formControl)
    this._formInputValidations = [];
    this.cssStyleHint = "primary-color";
    this.type = "text";
    this.isRequired = false;
  }

  public static create(controllerName: string, formGroup: UntypedFormGroup): FormInputParameters {

    return new FormInputParameters(controllerName, formGroup);

  }

  public setControl(value: any): FormInputParameters {
    this.controlName = value;
    return this;
  }

  public setHint(value: string, cssStyle?: string): FormInputParameters {
    this.hint = value;

    if (cssStyle) {
      this.cssStyleHint = cssStyle;
    }
    return this;
  }

  public addValidator(formInputValidation: FormInputValidationValue): FormInputParameters {
    this.formInputValidations.push(formInputValidation);
    let validators = this.formInputValidations.map( validatorElement => validatorElement.validator)
    this.formGroup.controls[this.controlName].setValidators(validators);
    return this;
  }

  public setReadOnly(value: boolean): FormInputParameters {
    this.readonly = value;
    return this;
  }

  public setPlaceHolder(value: string): FormInputParameters {
    this.placeholder = value;
    return this;
  }

  public setValue(value: string): FormInputParameters{
    
    this.formControl.setValue(value);
    this.formControl.updateValueAndValidity();
    this.formControl.disable();
    return this;
  }
  
  public setCssStyleInput(value: string): FormInputParameters {
    this.cssStyleInput = value;
    return this;
  }

  public setPattern(value: string): FormInputParameters {
    this.pattern = value;
    return this;
  }

  public setType(value: any): FormInputParameters {
    this.type = value;
    return this;
  }

  public setLabel(value: string): FormInputParameters {
    this.label = value;
    return this;
  }

  public setRequired(): FormInputParameters {
    this.isRequired = true;
    return this;
  }

  public getFormController(): UntypedFormControl {
    return this.formControl;
  }

}
