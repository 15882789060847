import {Component} from '@angular/core';
import {BalanceController} from "../../core/controllers/balance-controller";
import {ActivatedRoute, Router} from "@angular/router";
import {SnackbarService} from "../../core/lib/snackbar.service";

@Component({
  selector: 'app-conferimento',
  templateUrl: './conferimento.component.html',
  providers: [BalanceController]
})
export class ConferimentoComponent {

  constructor(
    private balanceController: BalanceController,
    private router: Router,
    private route: ActivatedRoute,
    private snackbarService: SnackbarService
  ) {

    this.route
      .queryParams
      .subscribe(p => {

        if (p['tid']) {

          this.postConferimento(p['tid']);

        } else {

          this.handleTransaction('Spiacenti, si è verificato un errore. Riprovare.');

        }

      });

  }

  postConferimento(transactionCode: string) {

    this.balanceController
      .post(transactionCode)
      .subscribe({
        next: (result: any) => this.handleTransaction('Operazione eseguita correttamente!'),
        error: (result: any) => this.router.navigate(['/'])
      });

  }

  private handleTransaction = (message: string) => {

    this.router
      .navigate(['/'])
      .then(() => this.snackbarService.openGenericSnackBar(message));

  };

}
