import {Component} from '@angular/core';
import {BalanceController} from 'src/app/core/controllers/balance-controller';
import {AppGeneralService} from 'src/app/core/lib/app-general.service';
import {Conferment} from "../../../../core/interfaces/conferment";

@Component({
  selector: 'app-conferments-list',
  templateUrl: './conferments-list.component.html',
  styleUrls: ['./conferments-list.component.scss'],
  providers: [BalanceController]
})
export class ConfermentsListComponent {

  conferimenti: Array<Conferment> = [];

  constructor(
    public appGeneralService: AppGeneralService,
    private balanceController: BalanceController
  ) {

    this.balanceController
      .getHistory()
      .subscribe((result: any) => this.conferimenti = result.result);

  }

}
