import {Injectable} from '@angular/core';
import {AbstractControl} from '@angular/forms';
import * as codiceFiscaleUtils from '@marketto/codice-fiscale-utils';

const {Validator} = codiceFiscaleUtils;

@Injectable({
  providedIn: 'root'
})
export class ValidatorService {

  constructor() {
  }

  fiscalCode(control: AbstractControl): { [key: string]: boolean } | null {

    if (control.value !== undefined && control.value !== null && !Validator.codiceFiscale(control.value).valid) {

      return {'FiscalCode': true};

    }

    return null;

  }

}
