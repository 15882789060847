import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NewsComponent} from './news.component';
import {MatExpansionModule} from '@angular/material/expansion';
import {AvailableQuizComponent} from './available-quiz/available-quiz.component';
import {QuizComponent} from './available-quiz/quiz/quiz.component';
import {BinderNewsListModule} from './binder-news-list/binder-news-list.module';
import {TitleHeadingModule} from 'src/app/components/title-heading/title-heading.module';
import {RouterModule} from '@angular/router';
import {MatLegacyRadioModule as MatRadioModule} from '@angular/material/legacy-radio';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatLegacyFormFieldModule as MatFormFieldModule} from '@angular/material/legacy-form-field';
import { QuestionarioController } from 'src/app/core/controllers/questionario-controller';
import { QuizCompletedComponent } from 'src/app/components/quiz-completed/quiz-completed.component';
import { MatIconModule } from '@angular/material/icon';


@NgModule({
  imports: [
    CommonModule,
    BinderNewsListModule,
    TitleHeadingModule,
    RouterModule,
    MatRadioModule,
    FormsModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatExpansionModule,
    MatIconModule
  ],
  declarations: [
    NewsComponent,
    AvailableQuizComponent,
    QuizComponent,
    QuizCompletedComponent
  ],
  exports: [
    NewsComponent,
    AvailableQuizComponent,
    QuizComponent
  ],
  providers: [QuestionarioController]
})
export class NewsModule {
}
