<div class="eco-21-cntr edit-profile-cntr">

  <title-heading [titleHeadingViewModel]="{ title: 'Modifica Profilo'}"></title-heading>

  <div class="container">

    <form [formGroup]="form">

      <form-input [parameters]="nickname"></form-input>

      <form-input [parameters]="name"></form-input>

      <form-input [parameters]="surname"></form-input>

      <form-input [parameters]="fiscalCode"></form-input>

      <form-input [parameters]="birthDate"></form-input>

      <!-- <div class="mb-4 position-relative">

        <span class="tari position-absolute" [autoClose]="'outside'" [ngbPopover]="popContent" [popoverTitle]="popTitle">
          <mat-icon class="tari-icon">info</mat-icon>
        </span>

        <form-input [parameters]="tariCode"></form-input>

      </div> -->

      <form-input [parameters]="email"></form-input>

      <div class="d-flex">

        <div class="w-25">

          <form-input [parameters]="prefixPhone"></form-input>

        </div>

        <div class="w-75">

          <form-input [parameters]="phone"></form-input>

        </div>

      </div>
<!--
      <ng-template #popTitle>
        Codice utente TARI
      </ng-template>

      <ng-template #popContent>
        <div class="popoverCntr">
          <img class="ml-3 mr-3" src="/assets/images/taricode.png" />
        </div>
      </ng-template> -->

      <div class="mt-4 m-auto text-center">

        <ct-button [parameters]="editButton"></ct-button>

      </div>

    </form>

    <div class="mb-5 mt-4 text-center">

      <ct-button [parameters]="deleteProfile"></ct-button>

    </div>


  </div>

</div>
