import { Component, OnInit } from '@angular/core';
import { EcoCouponData } from 'src/app/core/classes/eco-coupon-data';
import { AppGeneralService } from 'src/app/core/lib/app-general.service';

@Component({
  selector: 'app-eco-coupons',
  templateUrl: './eco-coupons.component.html',
  styleUrls: ['./eco-coupons.component.scss']
})
export class EcoCouponsComponent implements OnInit {

  ecoCouponList!: Array<EcoCouponData>

  constructor(

    public appGeneralService: AppGeneralService
  
  ){ }

  ngOnInit() {}

}

