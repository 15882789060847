import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BinderNewsListComponent} from './binder-news-list.component';
import {CtCardModule} from '@ctsolution/ct-framework';

import {TitleHeadingModule} from 'src/app/components/title-heading/title-heading.module';
import {CardModule} from 'src/app/components/card/card.module.';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {SelectOptionModule} from "../../home/components/select-option/select-option.module";

@NgModule({
  imports: [
    CardModule,
    CommonModule,
    CtCardModule,
    TitleHeadingModule,
    FormsModule,
    ReactiveFormsModule,
    SelectOptionModule
  ],
  declarations: [
    BinderNewsListComponent
  ],
  exports: [
    BinderNewsListComponent
  ]
})
export class BinderNewsListModule {
}
