import {AfterViewInit, Component, OnInit} from '@angular/core';
import { UntypedFormGroup} from '@angular/forms';
import {CtButtonParameters} from '@ctsolution/ct-framework';
import {AppGeneralService} from 'src/app/core/lib/app-general.service';
import {ProfileController} from "../../../core/controllers/profile-controller";
import {DateService} from "../../../core/lib/date.service";
import {Profile} from "../../../core/classes/profile";
import {AccountController} from "../../../core/controllers/account-controller";
import {SnackbarService} from "../../../core/lib/snackbar.service";
import * as $ from 'jquery';
import { FormInputParameters } from 'src/app/components/form-input/class/form-input-parameters';
import { FormInputValidationStandard, FormInputValidationValue } from 'src/app/components/form-input/class/form-input-validation';
import { ValidatorService } from 'src/app/core/lib/validator.service';
import { DeleteProfileComponent } from '../delete-profile/delete-profile.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss'],
  providers: [ProfileController, AccountController]
})


export class EditProfileComponent  implements OnInit, AfterViewInit{

  form: UntypedFormGroup = new UntypedFormGroup({});

  deleteProfile = CtButtonParameters
  .create((event) => this.openDeleteProfileDialog())
  .setCSS("general-button", true)
  .setContent("Elimina Profilo")



  name: FormInputParameters = FormInputParameters
  .create('name',this.form)
  .setLabel('Nome')
  .addValidator(FormInputValidationStandard.Required());

  nickname: FormInputParameters = FormInputParameters
  .create('nickname',this.form)
  .setLabel('Nickname')
  .addValidator(FormInputValidationStandard.Required())
  .setReadOnly(true);


  surname: FormInputParameters = FormInputParameters
  .create('surname',this.form)
  .setLabel('Cognome')
  .addValidator(FormInputValidationStandard.Required());

  // tariCode: FormInputParameters = FormInputParameters
  // .create('tariCode',this.form)
  // .setLabel('*Tari')
  // .addValidator(FormInputValidationStandard.MinLength(5))
  // .addValidator(FormInputValidationStandard.MaxLength(8))

  // .setHint('Lo trovi in bolletta. Attenzione il dato é obbligatorio per usufruire di eventuali sconti TARI');

  email: FormInputParameters = FormInputParameters
  .create('email',this.form)
  .setLabel('Email')
  .addValidator(FormInputValidationStandard.Required())
  .setReadOnly(true);


  birthDate: FormInputParameters = FormInputParameters
  .create('birthDate',this.form)
  .setLabel('Data di Nascita')
  .addValidator(FormInputValidationStandard.Required())
  .setType('date')

  fiscalCode: FormInputParameters = FormInputParameters
  .create('fiscalCode',this.form)
  .setLabel('Codice Fiscale')
  .addValidator(FormInputValidationValue.Create("FiscalCode",this.validatorService.fiscalCode,"Il Codice Fiscale non è valido"))
   .setReadOnly(true);


  prefixPhone: FormInputParameters = FormInputParameters
  .create('prefixPhone',this.form)
  .setLabel('Prefisso')
  .setPlaceHolder("+39")
  .addValidator(FormInputValidationStandard.Required());

  phone: FormInputParameters = FormInputParameters
  .create('phone',this.form)
  .setLabel('Numero di telefono')
  .addValidator(FormInputValidationStandard.Required())
  .addValidator(FormInputValidationStandard.MinLength(10))
  .addValidator(FormInputValidationStandard.MaxLength(10))
  .setType('number');


  editButton: CtButtonParameters = CtButtonParameters
    .create((event) => this.update())
    .setCSS("general-button", true)
    .setContent("Salva")

  countryCodes = [
    {
      name: 'Italy',
      dial_code: '+39',
      code: 'IT'
    }
  ];

  constructor(

    private appGeneralService: AppGeneralService,
    private profileController: ProfileController,
    private dateService: DateService,
    private accountController: AccountController,
    private snackbarService: SnackbarService,
    private validatorService: ValidatorService,
    public dialog: MatDialog
  ) {

  }
  maxDate: Date | undefined;

  ngOnInit() { }

  ngAfterViewInit() {

    this.getProfile();


  }

  getProfile() {

    this.profileController
      .get()
      .subscribe((data: any) => {

        let birthDate = '';

        if (data['result']['birthDate'] !== null && data['result']['birthDate'] !== '') {

          birthDate = this.dateService.convertCustomDate(data['result']['birthDate'].split(/\s+/)[0]);

        }

        this.form.setValue({
          name: data['result']['name'],
          nickname: data['result']['nickname'],
          surname: data['result']['surname'],
          email: data['result']['email'],
          birthDate: birthDate,
          fiscalCode: data['result']['fiscalCode'],
          prefixPhone: data['result']['prefixPhone'] ?? "+39",
          phone: data['result']['phone'],
        });

      });

  }

  update() {

    if (this.form.valid) {

      const profileParams = new Profile(
        this.form.get('name')?.value,
        this.form.get('nickname')?.value,
        this.form.get('surname')?.value,
        this.form.get('email')?.value,
        this.form.get('birthDate')?.value,
        this.form.get('fiscalCode')?.value,
        this.form.get('prefixPhone')?.value,
        this.form.get('phone')?.value.toString());

      this.profileController
        .update(profileParams)
        .subscribe(() => {

          this.accountController.refreshClaims();
          this.snackbarService.openGenericSnackBar('Profilo aggiornato')
          this.appGeneralService.back();

        });

    } else {

      setTimeout(() => {
        $('html, body').animate({
          scrollTop: $('.mat-error')?.first()?.offset()!.top - 150
        }, 1000);
      }, 0);

    }

  }

  openDeleteProfileDialog = () => this.dialog.open(DeleteProfileComponent, {maxHeight: '80vh', maxWidth: '500px'});

}
