import { Component, OnInit } from '@angular/core';
import { CtButtonParameters } from '@ctsolution/ct-framework';
import { BearerToken } from 'src/app/core/interfaces/bearer-token';
import { AppGeneralService } from 'src/app/core/lib/app-general.service';
import { AuthService } from 'src/app/core/lib/auth/auth.service';
import { Avatar } from 'src/app/core/lib/avatar/avatar';
import { AvatarService } from 'src/app/core/lib/avatar/avatar.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  loggedUser: BearerToken;

  avatar?: Avatar;
  userLevel: number = 0;

  viewModel: any = {

    buttons: this.GetButtons()

  }

  constructor(

    private appGeneralService: AppGeneralService,
    private authService: AuthService,
    private avatarService: AvatarService

  ) {

    this.loggedUser = this.authService.getDecodedBearerToken();
    this.userLevel = this.authService.getLevel();

    this.avatarService
      .getUserAvatar()
      .then((result: Avatar) => this.avatar = result);
   }

  ngOnInit() {

  }

  GetButtons(): Array<CtButtonParameters> {
    return [
      CtButtonParameters
        .create((event) => { this.appGeneralService.navigateTo('/profilo/avatars') })
        .setCSS("general-button", true)
        .setContent("Modifica Avatar"),

      CtButtonParameters
        .create((event) => { this.appGeneralService.navigateTo('/profilo/famigliare') })
        .setCSS("general-button", true)
        .setContent("Famigliare"),

      CtButtonParameters
        .create((event) => { this.appGeneralService.navigateTo('/impostazioni') })
        .setCSS("general-button", true)
        .setContent("Impostazioni")

    ];
    
  }

}


