<ng-container  *ngIf="selectOptionViewModel && selectOptionViewModel.control">

  <mat-form-field class="w-100" appearance="outline">

    <mat-label>{{selectOptionViewModel.label}}</mat-label>

    <mat-select matNativeControl  [formControl]="selectOptionViewModel.control" (selectionChange)="change.emit()">

      <mat-option *ngFor="let type of selectOptionViewModel.types"  [value]="type.id" (blur)="blur.emit()">{{type.descrizione}}{{type.place}}</mat-option>

    </mat-select>

  </mat-form-field>

</ng-container>


