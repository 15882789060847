import { Injectable } from '@angular/core';
import { CtWebviewService } from '@ctsolution/ct-webview';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ExternalLinkService {


  constructor(

    private ctWebViewService: CtWebviewService

  ) { }

  openTermsConditions() {

    const url = `${environment.termsConditionsUrl}`;

    if (this.ctWebViewService.isCtWebView()) {

      window.location.href = url;

    } else {

      window.open(url, "_blank");

    }

  }

}

