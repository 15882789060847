import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AppGeneralService} from 'src/app/core/lib/app-general.service';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit {

  address: any;

  news: Array<INews> = [

    // {
    //   title: 'TROVA UN ECOVAN',
    //   description: 'Tipologie raccolta',
    //   path: 'ecovans',
    //   image: 'assets/images/news/news_1.png'
    // }, modulo "trova un ecovan da reintegrare nel caso in cui lo chiedano"
    {
      title: 'QUANTO NE SAI?',
      description: 'Quiz - guadagna Punti Speciali',
      path: 'quiz',
      image: 'assets/images/news/news_2.png'
    }

  ];

  constructor(
    public appGeneralService: AppGeneralService,
    private router: Router,
    private activateRoute: ActivatedRoute
  ) {
  }

  ngOnInit() {
  }

  goTo(path: string) {

    if (path && path !== '/') {

      this.router.navigate([path], {relativeTo: this.activateRoute});

    }

  }

}

export interface INews {

  title: string;
  description: string;
  path: string;
  image: string

}
