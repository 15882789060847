import {FormControl, Validator, Validators} from "@angular/forms";

export class GoogleInputAutocompleteConfiguration {

  control: FormControl = new FormControl();

  private _label: string = "Inserisci la tua via";
  get label(): string {

    return this._label;

  }

  private set label(value: string) {

    this._label = value;

  }

  private _placeholder: string = 'Via Giosué Carducci 10';
  get placeholder(): string {

    return this._placeholder;

  }

  private set placeholder(value: string) {

    this._placeholder = value;

  }

  private _country: string[] = ['it'];
  get country(): string[] {

    return this._country;

  }

  private set country(value: string[]) {

    this._country = value;

  }

  private _type: string[] = ['address'];
  get type(): string[] {

    return this._type;

  }

  private set type(value: string[]) {

    this._type = value;

  }

  constructor() {
  }

  public static create = (): GoogleInputAutocompleteConfiguration => new GoogleInputAutocompleteConfiguration();

  setRequired(): GoogleInputAutocompleteConfiguration {

    this.control.setValidators(Validators.required);
    return this;

  }

  setLabel(value: string): GoogleInputAutocompleteConfiguration {

    this.label = value;
    return this;

  }

  setPlaceholder(value: string): GoogleInputAutocompleteConfiguration {

    this.placeholder = value;
    return this;

  }

  setCountry(value: string[]): GoogleInputAutocompleteConfiguration {

    this.country = value;
    return this;

  }

  setType(value: string[]): GoogleInputAutocompleteConfiguration {

    this.type = value;
    return this;

  }

}
