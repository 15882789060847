import {ApplicationConfig} from "@ctsolution/ct-webview";

export const APPLICATION_CONFIG: ApplicationConfig = {
  Android: {
    package: 'it.ecoxxi.app'
  },
  Apple: {
    scheme: 'ecoxxi',
    storeLink: ''
  }
}
