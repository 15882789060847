<div class="eco-21-cntr sign-up-cntr">

  <div class="container h-100">

    <title-heading [titleHeadingViewModel]="{ title: 'Registrati', subtitle:'Inserisci i tuoi dati'}"></title-heading>

  </div>

  <app-registration-form></app-registration-form>

</div>
