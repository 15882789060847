<div class="eco-21-cntr historical-cntr">

  <title-heading [titleHeadingViewModel]="{ title: 'Storico'}"></title-heading> 

  <div class="container h-100">

    <app-medals></app-medals>

    <div class=" w-75 m-auto separetor mb-3"></div>

    <app-accumulated-balance></app-accumulated-balance>

    <div class=" w-75 m-auto separetor mt-3"></div>

    <div class="mt-5 pb-5">

      <app-conferments-list></app-conferments-list>

    </div>

  </div>

</div>
