import {Component, OnInit} from '@angular/core';
import {AuthService} from 'src/app/core/lib/auth/auth.service';
import * as $ from 'jquery';
import {BalanceController} from "../../../../core/controllers/balance-controller";

@Component({
  selector: 'app-medals',
  templateUrl: './medals.component.html',
  styleUrls: ['./medals.component.scss'],
  providers: [BalanceController]
})
export class MedalsComponent implements OnInit {

  userLevel: number = 0;

  constructor(private authService: AuthService) {

    this.userLevel = this.authService.getLevel();

    if (this.userLevel > 4) {

      let offset = 0;
      if (window.innerWidth >= 575) {
        offset = 200;
      }

      $('.pnlMedals').animate({marginLeft: '-' + ($('.singleMedal.active').position().left - offset) + 'px'}, 2000);

    }

  }

  ngOnInit() {
  }

}
