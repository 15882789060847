import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {CardModule} from 'src/app/components/card/card.module.';
import {TitleHeadingModule} from 'src/app/components/title-heading/title-heading.module';
import {AffiliateBusinessComponent} from './affiliate-business/affiliate-business.component';
import {PartnerComponent} from './affiliate-business/partner/partner.component';
import {CompactorsComponent} from './compactors/compactors.component';
import {SelectOptionModule} from '../components/select-option/select-option.module';
import {FormInputModule} from 'src/app/components/form-input/form-input.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatLegacyFormFieldModule as MatFormFieldModule} from '@angular/material/legacy-form-field';
import {
  GoogleInputAutocompleteModule
} from "../../../components/google-input-autocomplete/google-input-autocomplete.module";
import { CtButtonModule } from '@ctsolution/ct-framework';
import { MatIconModule } from '@angular/material/icon';


@NgModule({

  imports: [
    CommonModule,
    TitleHeadingModule,
    CardModule,
    CtButtonModule,
    SelectOptionModule,
    RouterModule,
    FormInputModule,
    FormsModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    GoogleInputAutocompleteModule,
    MatIconModule
  ],
  declarations: [
    CompactorsComponent,
    AffiliateBusinessComponent,
    PartnerComponent
  ],
  exports: [
    CompactorsComponent,
    AffiliateBusinessComponent,
    PartnerComponent
  ]
})

export class SearchModule {
}
