import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PersonalTotalScoreComponent} from './personal-total-score.component';
import { BalanceController } from 'src/app/core/controllers/balance-controller';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [PersonalTotalScoreComponent],
  exports: [PersonalTotalScoreComponent],
  providers: [BalanceController]
})
export class PersonalTotalScoreModule {
}
