import { Component, OnInit } from '@angular/core';
import { CtButtonParameters } from '@ctsolution/ct-framework';
import { AppGeneralService } from 'src/app/core/lib/app-general.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  buttons: Array<ImenuButtons> = [

  {
  icon: 'home',
  path:'/'
 },
  {
  icon: 'local_activity',
  path:'/coupons'
 },
  {
  icon: 'grade',
  path:'/missioni'
 },
  {
  icon: 'workspace_premiumm',
  path:'/classifica'
 },
  {
  icon: 'receipt_long',
  path:'/news'
 },
  {
  icon: 'person',
  path:'/profilo'
 }

  ];

  constructor(
   
  ) { }



  ngOnInit() { }

}

export interface ImenuButtons{

  icon: string;
  path: string;
  
}
