export class Avatar {

  constructor(
    public key: string,
    public code: number,
    public name: string,
    public locked: boolean,
    public level: number) {
  }

  getFullPath = () => `assets/images/avatars-ecoxxi/${this.key}.svg`;

}

export const Avatars: Array<Avatar> = [
  new Avatar('procione', 1, 'Procione', false, 0),
  new Avatar('gatto', 2, 'Gatto', false, 0),
  new Avatar('riccio', 11, 'Riccio', false, 1),
  new Avatar('cane', 12, 'Cane', false, 1),
  new Avatar('balena', 21, 'Balena', false, 2),
  new Avatar('gallina', 22, 'Gallina', false, 2),
  new Avatar('bufalo', 31, 'Bufalo', false, 3),
  new Avatar('pecora', 32, 'Pecora', false, 4),
  new Avatar('topo', 41, 'Topo', false, 3),
  new Avatar('asinello', 42, 'Asinello', false, 4),
  new Avatar('panda', 51, 'Panda', false, 5),
  new Avatar('maiale', 52, 'Maiale', false, 6),
  new Avatar('uccello', 61, 'Uccello', false, 5),
  new Avatar('elefante', 62, 'Elefante', false, 6),
  new Avatar('ariete', 71, 'Ariete', false, 7),
  new Avatar('orso', 72, 'Orso', false, 8),
  new Avatar('toro', 81, 'Toro', false, 7),
  new Avatar('scimmia', 82, 'Scimmia', false, 8)
  // new Avatar('rhino', 91, 'RINOCERONTE', false, 9),
  // new Avatar('rhino_super', 92, 'SUPER RINOCERONTE', false, 10),
  // new Avatar('sea_horse', 101, 'CAVALLUCCIO', false, 9),
  // new Avatar('sea_horse_super', 102, 'SUPER CAVALLUCCIO', false, 10),
  // new Avatar('shark', 111, 'SQUALO', false, 9),
  // new Avatar('shark_super', 112, 'SUPER SQUALO', false, 10),
  // new Avatar('sperm_whale', 121, 'CAPODOGLIO', false, 9),
  // new Avatar('sperm_whale_super', 122, 'SUPER CAPODOGLIO', false, 10)
];
