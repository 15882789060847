import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { EcoCouponData } from 'src/app/core/classes/eco-coupon-data';
import { PartnersCouponController } from 'src/app/core/controllers/partner-coupon-controller';
import { AppGeneralService } from 'src/app/core/lib/app-general.service';

@Component({
  selector: 'app-historical-eco-coupons',
  templateUrl: './historical-eco-coupons.component.html',
  styleUrls: ['./historical-eco-coupons.component.scss']
})
export class HistoricalEcoCouponsComponent implements OnInit {

   ecoCouponList!: Array<EcoCouponData>
   
  @ViewChild('scroller') private scroll_cntr: ElementRef | undefined;

  constructor(
    public appGeneralService: AppGeneralService,
    private ecoCouponController: PartnersCouponController
  ) {

    this.ecoCouponController  
    .getHistory()
    .subscribe((result: any) => {
      var coupons = result.result as Array<EcoCouponData>;
      this.ecoCouponList = coupons.filter(coupon => coupon.despatchedAt != null);

    } );
   }

  ngOnInit() {
  }

  scrollToBottom(): void {

    setTimeout(() => {

      try {
        this.scroll_cntr!.nativeElement.scrollTop = this.scroll_cntr!.nativeElement.scrollHeight;

      } catch (err) {
      }

    }, 0);


  }
  
  formatDate = (date:string) => this.appGeneralService.toShortDate(date);

}
