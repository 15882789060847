import {Component, OnInit} from '@angular/core';
import {PartnerResult} from "../../../../../core/interfaces/partner-result";
import {ActivatedRoute} from "@angular/router";
import {PartnersController} from "../../../../../core/controllers/partners-controller";
import { CtButtonParameters } from '@ctsolution/ct-framework';
import { PartnersCouponController } from 'src/app/core/controllers/partner-coupon-controller';
import { PartnerData } from 'src/app/core/classes/partner-data';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { AppGeneralService } from 'src/app/core/lib/app-general.service';
import { Products } from 'src/app/core/interfaces/products';
import { BalanceController } from 'src/app/core/controllers/balance-controller';


@Component({
  selector: 'app-partner',
  templateUrl: './partner.component.html',
  styleUrls: ['./partner.component.scss']
})
export class PartnerComponent implements OnInit {

  partner!: PartnerResult;
  partnerid: number = 0;
  balance?: any;

  constructor(
    private route: ActivatedRoute,
    private appGeneralService: AppGeneralService,
    private partnersController: PartnersController,
    private partnersCouponController: PartnersCouponController,
    private snackbar: MatSnackBar,
    private balanceController: BalanceController

  ) {
  }

  ngOnInit() {

   this.getBalance();

   this.getPartner();

  }

  getBalance(){

    this.balance = this.balanceController.get().subscribe((result: any) =>{ this.balance = result?.result?.points })

  }

  getPartner() {

    this.route
      .params
      .subscribe((params) => {

        this.partnerid = params['partnerid'];

        this.partnersController
          .get(params['partnerid'])
          .subscribe((partner: any) => this.partner = partner['result']);

      });

  }

  generateParameters(product: Products):CtButtonParameters{
    return CtButtonParameters
            .create((event) => {this.shop(product.codice)})
            .setCSS("general-button", true)
            .setContent("Riscatta Coupon")
  }


  shop(productCode:string){

    const data: PartnerData ={

      PartnerId: this.partnerid,
      ProductCode: productCode

    }

    this.partnersCouponController.post(data).subscribe((result:any)=>{

      this.snackbar.open(result['message'],'X',{
        duration: 5000
      });

      this.appGeneralService.navigateTo('/coupons');

    });

  }

}


