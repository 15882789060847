import { Component, OnInit } from '@angular/core';
import { CtButtonParameters } from '@ctsolution/ct-framework';


@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit {

  // viewModel: any = {

  //   avatars: this.GetAvatars()

  // }

  constructor() { }

  ngOnInit() {
  }

    
  // GetAvatars(): Array<CtButtonParameters>{
  //   return [
      
  //     CtButtonParameters
  //     .create((event) => { })
  //     .setImage("/assets/images/avatars-ecoxxi/procione.svg")
  //     .setCSS('avatar-cntr')
  //     .setImageCss("avatarImage"),

  //     CtButtonParameters
  //     .create((event) => { })
  //     .setImage("/assets/images/avatars-ecoxxi/gatto.svg")
  //     .setCSS('avatar-cntr')
  //     .setImageCss("avatarImage")

  //   ];
    
  // }


}
