import {Injectable} from "@angular/core";
import {CtWebapiService, DataRequest, MethodEnum} from "@ctsolution/ct-webapi";
import {Observable} from "rxjs";
import {CallResult} from "../classes/call-result";
import {EcoCouponData} from "../classes/eco-coupon-data";
import {PartnerData} from "../classes/partner-data";
import {RendeemData} from "../classes/rendeem-data";

@Injectable()

export class PartnersCouponController {

  private controller: string = 'PartnerCoupon';

  constructor(private ctWebApiService: CtWebapiService) {
  }

  get(couponId: number): Observable<CallResult> {

    const request: DataRequest = new DataRequest(`${this.controller}/${couponId}`, {hideSpinner: true});
    return this.ctWebApiService.get(request);
  }


  getHistory(): Observable<CallResult> {

    const request: DataRequest = new DataRequest(`${this.controller}`);
    return this.ctWebApiService.get(request);

  }

  post(PartnerData: PartnerData): Observable<CallResult> {

    const request: DataRequest = new DataRequest(`${this.controller}`, {hideSpinner: true});
    return this.ctWebApiService.post(request, PartnerData);

  }


  put(CouponId: RendeemData): Observable<CallResult> {

    let request: DataRequest = new DataRequest(`${this.controller}`);

    request.setMethod(MethodEnum.PUT);
    request.setBody(CouponId);

    return this.ctWebApiService.request(request);

  }

}

