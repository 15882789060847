<div class="recovery-password-cntr">

  <div class="container h-100">

    <title-heading [titleHeadingViewModel]="{ title: 'Recupera la password'}"></title-heading>

    <form [formGroup]="form">

      <form-input [parameters]="email"></form-input>

      <div class="mt-4 m-auto text-center">

        <ct-button [parameters]="sendButton"></ct-button>

      </div>

    </form>

  </div>

</div>