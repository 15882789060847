export class RegisterFamiliarData {
  constructor(
    public Avatar: number,
    public Nickname: string,
    public Password: string,
    public Email: string,
    public Token: string
  ) {
  }
}
