<div class="delete-profile-cntr container">

  <mat-icon class="alert-icon"> notification_important</mat-icon>
  
    <h2 class="primary-color text-center">Elimina Profilo</h2>

    <p class="dark-green"><strong>Confermi di voler eliminare il tuo profilo?</strong></p>

    <small>Una volta eliminato il profilo non potrai più accedere all'applicazione ed usufruire dei vantaggi ad essa associati.</small>

    <div class="row mt-4">

      <div class="col-6">

        <ct-button [parameters]="deleteButton"></ct-button>

      </div>

      <div class="col-6">

        <ct-button [parameters]="annulla"></ct-button>

      </div>
    
    </div>

</div>