export class Profile {

  constructor(
    public name: string,
    public nickname: string,
    public surname: string,
    public email: string,
    public birthDate: Date,
    public fiscalCode: string,
    public prefixPhone: string,
    public phone: string
  ) {
  }

}
