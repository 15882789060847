<div class="eco-21-cntr settings-cntr h-100">

  <div class="container">

    <title-heading [titleHeadingViewModel]="{ title: 'Impostazioni'}"></title-heading>

    <div class="row mt-5 setting-buttons">

      <ng-container *ngFor="let button of viewModel.buttons">

        <div class="text-center col-10 mt-2 mb-3 m-auto">

            <ct-button [parameters]="button"></ct-button>     
  
        </div>
  
      </ng-container> 

    </div>

  </div>

</div>