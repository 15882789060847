<div class="container eco-21-cntr rank-cntr h-100">

  <title-heading [titleHeadingViewModel]="{ title: 'Classifica'}"></title-heading> 


  <div class="mat-tab-group-cntr">

    <mat-tab-group mat-align-tabs="center">

      <mat-tab label="Mensile">

        <div class="mat-tab-cntr">

          <ng-container *ngIf="utentiMensili.length > 0; else emptyList">

            <ng-container *ngFor="let item of utentiMensili; index as i">

              <app-user-card [user]="item" [position]="i+1"></app-user-card>

            </ng-container>

          </ng-container>

        </div>

      </mat-tab>

      <mat-tab label="Settimanale">

        <div class="mat-tab-cntr">

          <ng-container *ngIf="utentiSettimanali.length > 0; else emptyList">

            <ng-container *ngFor="let item of utentiSettimanali; index as i">

              <app-user-card [user]="item" [position]="i+1"></app-user-card>

            </ng-container>

          </ng-container>

        </div>

      </mat-tab>

    </mat-tab-group>

  </div>

</div>


<ng-template #emptyList>

  <p class="text-center"> Non ci sono elementi disponibili.</p>

</ng-template>
