import {Component} from '@angular/core';
import { UntypedFormGroup, Validators} from '@angular/forms';
import {CtButtonParameters} from '@ctsolution/ct-framework';
import { FormInputParameters } from 'src/app/components/form-input/class/form-input-parameters';
import { FormInputValidationStandard, FormInputValidationValue } from 'src/app/components/form-input/class/form-input-validation';
import {AppGeneralService} from 'src/app/core/lib/app-general.service';
import {PasswordController} from "../../core/controllers/password-controller";
import {SnackbarService} from "../../core/lib/snackbar.service";

@Component({
  selector: 'app-recovery-password',
  templateUrl: './recovery-password.component.html',
  styleUrls: ['./recovery-password.component.scss'],
  providers: [PasswordController]
})
export class RecoveryPasswordComponent {

  form:  UntypedFormGroup = new UntypedFormGroup({});
  successMessage: string = "Ti abbiamo inviato una mail, aprila e clicca sul link per recuperare la password!"

  email: FormInputParameters = FormInputParameters
  .create('email',this.form)
  .setLabel('Email')
  .addValidator(FormInputValidationStandard.Required())
  .addValidator(FormInputValidationValue.Create("email",Validators.email,"La mail non è valida"));

  sendButton: CtButtonParameters = CtButtonParameters
    .create((event) => this.recover())
    .setCSS("general-button", true)
    .setContent("Invia")

  constructor(
  
    private appGeneralService: AppGeneralService,
    private passwordController: PasswordController,
    private snackbarService: SnackbarService
  ) {

  }

  recover() {

    if (this.form.valid) {

      this.passwordController
        .recover(this.form.value.email)
        .subscribe(() => {

          this.snackbarService.openGenericSnackBar(this.successMessage);
          this.appGeneralService.navigateTo('/');

        });

    }

  }

}
