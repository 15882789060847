<div class="registration-form-cntr">

  <div class="container h-100">

    <form [formGroup]="form">

      <div class="avatar-choise-cntr pb-4">

        <div class="container row m-0">

          <div class="ecoxxi-avatar avatar-1 col-6" [class.off]="selectedGender !== 1" (click)="selectedGender = 1"
            [class.jello-horizontal]="femaleBounce" (mouseover)="femaleBounce=true" (mouseout)="femaleBounce=false"></div>

          <div class="ecoxxi-avatar avatar-2 col-6" [class.off]="selectedGender !== 2" (click)="selectedGender = 2"
            [class.jello-horizontal]="maleBounce" (mouseover)="maleBounce=true" (mouseout)="maleBounce=false">
          </div>

        </div>

      </div>

      <div class="mb-4">

        <form-input [parameters]="nickname"></form-input>

      </div>
      <div class="mb-2">

        <form-input [parameters]="fiscalCode"></form-input>

      </div>
      <div class="mb-2">

        <form-input [parameters]="email"></form-input>


      </div>

      <div class="mb-4">

        <form-input [parameters]="password"></form-input>

      </div>
<!--
      <div class="mb-5 position-relative">

        <span class="tari position-absolute" [autoClose]="'outside'" [ngbPopover]="popContent" [popoverTitle]="popTitle">
          <mat-icon class="tari-icon">info</mat-icon>
        </span>

        <form-input [parameters]="tari"></form-input>

      </div> -->

      <div class="mt-2 mb-4">

          <mat-checkbox class="me-3" formControlName="termsConditions" required >Accetto i <small class="dark-green text-decoration-underline" (click)="openTermsConditions()"><strong>Termini e Condizioni</strong></small></mat-checkbox>

          <div class="mt-2">

            <mat-hint class="primary-color" *ngIf="form.get('termsConditions')?.hasError('required')"><small>*Attenzione! Campo obbligatorio</small></mat-hint>

          </div>

      </div>

      <!-- <ng-template #popTitle>
        Codice utente TARI
      </ng-template>

      <ng-template #popContent>
        <div class="popoverCntr">
          <img class="ml-3 mr-3" src="/assets/images/taricode.png" />
        </div>
      </ng-template> -->

      <div class="mt-5 mb-4 w-50 m-auto text-center">

        <ct-button [parameters]="signUpButton"></ct-button>

      </div>

    </form>

  </div>

</div>
