export class PartnersResearch {

  punti?: number;

  constructor(
    public areaGeografica?: number,
    public categoria?: number) {
  }

}
