import {Component, OnInit} from '@angular/core';
import {AreaGeograficaController} from 'src/app/core/controllers/area-geografica.controller';
import {BindersController} from 'src/app/core/controllers/binders-controller';
import {BINDER_TYPE} from 'src/app/core/enums/binder-type.enum';
import {AppGeneralService} from 'src/app/core/lib/app-general.service';
import {MapsService} from 'src/app/core/lib/maps.service';
import {BindersResearchResults} from "../../../../core/interfaces/binders-research-results";
import {
  GoogleInputAutocompleteConfiguration
} from "../../../../components/google-input-autocomplete/google-input-autocomplete.configuration";

@Component({
  selector: 'app-compactors',
  templateUrl: './compactors.component.html',
  styleUrls: ['./compactors.component.scss'],
  providers: [BindersController]
})
export class CompactorsComponent implements OnInit {

  compactors: Array<BindersResearchResults> = [];
  googleInputAutoCompleteConfiguration: GoogleInputAutocompleteConfiguration = GoogleInputAutocompleteConfiguration.create();

  constructor(
    public AppGeneralService: AppGeneralService,
    private BindersController: BindersController,
    private maps: MapsService,
  ) {
  }

  ngOnInit() {

    this.research();

  }

  research(latitude: number = this.maps.getLastSearchedLocation().latitude, longitude: number = this.maps.getLastSearchedLocation().longitude) {

    this.compactors = [];

    this.maps.saveLastSearchedLocation(this.googleInputAutoCompleteConfiguration.control.value, latitude, longitude);

    this.BindersController
      .research(BINDER_TYPE.COMPATTATORE, latitude, longitude)
      .subscribe((result: any) => {

        this.compactors = result['result'];
        this.compactors
          .forEach((binder) => {
            binder.mediumimmagine = "/assets/images/COMPATTATORE_PLASTIPREMIA.png"
          })

      });

  }

}
