<div class="eco-coupons-cntr h-100">

  <div class="coupon-sticky" type="button" [routerLink]="['/partners']">

    <mat-icon>shopping_bag</mat-icon>

  </div>

  <div class="container h-100 pb-5">

    <title-heading [titleHeadingViewModel]="{ title: 'I miei Coupon', class:'mb-5'}"></title-heading>

    <app-eco-coupon-list></app-eco-coupon-list>

    <div class="separetor mt-4 mb-4"></div>

    <div class="coupon-historical-cntr">

      <h2 class="text-center light-green font-weight-light mb-4">Storico Coupon</h2>

      <app-historical-eco-coupons></app-historical-eco-coupons>

    </div>

  </div>

</div>