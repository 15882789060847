import {Component, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {CtButtonParameters} from '@ctsolution/ct-framework';
import {RegistrationController} from "../../../core/controllers/registration-controller";
import { FormInputParameters } from 'src/app/components/form-input/class/form-input-parameters';
import { FormInputValidationStandard, FormInputValidationValue } from 'src/app/components/form-input/class/form-input-validation';
import { ValidatorService } from 'src/app/core/lib/validator.service';
import { PasswordStrengthValidator } from '../../password-strength.validator';
import { ExternalLinkService } from 'src/app/core/lib/external-link.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-registration-form',
  templateUrl: './registration-form.component.html',
  styleUrls: ['./registration-form.component.scss'],
  providers: [RegistrationController]
})
export class RegistrationFormComponent implements OnInit {

  form: UntypedFormGroup = new UntypedFormGroup({termsConditions: new UntypedFormControl(false)});

  nickname: FormInputParameters = FormInputParameters
  .create('nickname',this.form)
  .setLabel('Nickname')
  .addValidator(FormInputValidationStandard.Required())
  .addValidator(FormInputValidationStandard.MinLength(5))
  .addValidator(FormInputValidationValue.Create("pattern",Validators.pattern('^[A-Za-z0-9]+$'),"Il Nickname non può contenere caratteri speciali"))
  .setHint('*Non sono consentiti spazi, due punti o virgolette. Può comprendere un massimo di 20 caratteri e un minimo di 5');

  fiscalCode: FormInputParameters = FormInputParameters
  .create('fiscalCode',this.form)
  .setLabel('Codice Fiscale')
  .addValidator(FormInputValidationStandard.Required())
  .addValidator(FormInputValidationValue.Create("FiscalCode",this.validatorService.fiscalCode,"Il Codice Fiscale non è valido"));

  email: FormInputParameters = FormInputParameters
  .create('email',this.form)
  .setLabel('Email')
  .addValidator(FormInputValidationStandard.Required())
  .addValidator(FormInputValidationValue.Create("email",Validators.email,"La mail non è valida"));

  password: FormInputParameters = FormInputParameters
  .create('password',this.form)
  .setLabel('Password')
  .setType('password')
  .addValidator(FormInputValidationStandard.Required())
  .addValidator(FormInputValidationStandard.MinLength(6))
  .addValidator(FormInputValidationValue.Create("passwordStrength",PasswordStrengthValidator,"Deve contenere una lettera maiuscola,una lettera minuscola e un numero"))
  .setHint('*Deve essere composta da almeno 6 caratteri, contenere almeno una lettera maiuscola,una lettera minuscola e un numero');

  // tari: FormInputParameters = FormInputParameters
  // .create('tari',this.form)
  // .setLabel('*Tari')
  // .setHint('*Lo trovi in bolletta. Attenzione! Il dato é obbligatorio per usufruire di eventuali sconti TARI')
  // .addValidator(FormInputValidationStandard.MinLength(5))
  // .addValidator(FormInputValidationStandard.MaxLength(8));

  selectedGender: number = 1;
  femaleBounce: boolean = false;
  maleBounce: boolean = false;


  signUpButton: CtButtonParameters = CtButtonParameters
    .create((event) => this.register())
    .setCSS("general-button", true)
    .setContent("Registrati")

  constructor(

    private registrationController: RegistrationController,
    private validatorService: ValidatorService,
    private termsConditions: ExternalLinkService


  ) {}

  ngOnInit() { }

  openTermsConditions() {

    this.termsConditions.openTermsConditions();

  }

  register() {

    if (this.form.valid) {

      this.registrationController.signup(

        this.selectedGender,
        this.form.value.nickname,
        this.form.value.fiscalCode,
        this.form.value.email,
        this.form.value.password,
        // this.form.value.tari

      );

    }

  }

}


