<div class="eco-21-cntr edit-password-cntr h-100">

  <title-heading [titleHeadingViewModel]="{ title: 'Modifica Password'}"></title-heading>

  <div class="container h-100">

    <form [formGroup]="form">

      <form-input [parameters]="password"></form-input>

      <div class="mb-4">

        <form-input [parameters]="passwordNew"></form-input>

      </div>      

      <form-input [parameters]="passwordConfirm"></form-input>
      <mat-error *ngIf="onMatchErrorFormGroup()">Le password non coincidono</mat-error>

      <div class="mt-4 m-auto text-center">

        <ct-button [parameters]="editButton"></ct-button>

      </div>


    </form>

  </div>

</div>