<div class="add-familiar-member-cntr h-100">

    <div class="container">

      <form [formGroup]="form">

        <form-input [parameters]="name"></form-input>

        <form-input [parameters]="email"></form-input>

        <form-input [parameters]="fiscalCode"></form-input>

        <div class="mt-4 w-50 m-auto text-center">

          <ct-button [parameters]="addButton"></ct-button>

        </div>

      </form>

      <div class="separetor mt-4 mb-4"></div>
  

      <h2 class="text-center primary-color">Famiglia</h2>

      <div class="row pb-5">

        <ng-container *ngIf="familiars.length > 0; else no_familiar">

          <div class="col-6 col-md-4" *ngFor="let avatar of familiars">


            <div class="d-flex w-100 mt-3">

              <div class="eco-21-avatar m-auto"
                [ngStyle]="{ 'background-image': 'url('+ getAvatarPathImage(avatar.avatar) + ')'}"></div>

            </div>

            <div class="text-center">

              <h3>{{avatar.name}}</h3>

            </div>

          </div>

        </ng-container>

        <ng-template #no_familiar>

          <p class="text-center">Non hai associato nessun famigliare.</p>

        </ng-template>


      </div>

  </div>