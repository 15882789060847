<div class="coupon-list-cntr mb-4 ml-4 mr-4" #scroller>

    <div class="container h-100" *ngIf="ecoCouponList && ecoCouponList.length; else no_coupons">

   
      <ng-container *ngFor="let ecoCoupon of ecoCouponList">

          <div class="card-border b-radius ps-2 pt-2 pb-4 mb-4">

          <small class="light-green font-weight-bolder" [innerHTML]="this.formatDate(ecoCoupon.createdAt)"></small>

          <div class="font-weight-bolder dark-green mt-3" [innerHTML]="ecoCoupon.partnerName"></div>
  
          <p class="font-style-italic primary-color mt-2">{{ecoCoupon.productAmount}}  PUNTI</p>
  
          <div class="conferment-message mt-1 font-weight-light" [innerHTML]="ecoCoupon.productDescription"></div>

          <div class="text-center mt-4">

            <button class="general-button w-50 m-auto" (click)="request(ecoCoupon.couponId, ecoCoupon.partnerName)">Utilizza</button>

          </div>

          </div>       

      </ng-container>

    </div>

  <ng-template #no_coupons>

    <div class="text-center card-border b-radius pt-5 pb-5">

      <b>Non hai riscattato nessun coupon.</b>

    </div>

  </ng-template>

</div>