<div class="eco-21-cntr sign-in-cntr container h-100">

  <div class="logo-eco-21 mb-5">

    <img class="w-100" src="/assets/images/logos/logo-eco-21/logo_ecoxxi_full.png" alt="logo">
    
  </div>



    <div class="background-avatar"></div>

    <div class="row mt-4">

      <ng-container *ngFor="let button of viewModel.buttons">

        <div class=" col-7 text-center mt-2 mb-3 m-auto">

          <ct-button [parameters]="button"></ct-button>

        </div>

      </ng-container>

    </div>

</div>
 

<!--   *****SEZIONE PER I PARTNER SE LA VOLESSERO IN FUTURO******** -->

<!-- 
    <div class="separator"></div>

    <p class="text-center content-style">Sei un'azienda?</p>

    <div class="row">

      <div class=" col-7 text-center mt-2 mb-3 m-auto">

        <ct-button [parameters]="viewModel.beginPartner"></ct-button>

      </div>

    </div> -->
