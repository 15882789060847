import {COUPON_TYPE} from '../enums/coupon-type.enum';
import {CallResult} from './../classes/call-result';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import {AuthService} from '../lib/auth/auth.service';
import {CtWebapiService, DataRequest} from '@ctsolution/ct-webapi';

@Injectable()
export class CouponController {

  private controller: string = 'Coupon';

  constructor(
    private ctWebApiService: CtWebapiService,
    private _auth: AuthService
  ) {
  }

  getCoupons() {

    const request: DataRequest = new DataRequest(this.controller, {
      hideSpinner: true,
    });

    return this.ctWebApiService.get(request);

  }

  getTicket(id: number) {

    const encodedEmail = btoa(this._auth.getUserEmail());
    const url = `${environment.API_URL}Biglietto/?couponId=${id}&em=${encodedEmail}&.pdf=true`;

    window.open(url, '_blank');

  }

  requestCoupon(CouponType: COUPON_TYPE, reservationDate?: Date): Observable<CallResult> {

    const parameter: { tipoCoupon: COUPON_TYPE, reservationDate?: string } = {tipoCoupon: CouponType};

    const request: DataRequest = new DataRequest(this.controller);

    if (reservationDate) {

      reservationDate = this.convertToTimeZoneOffset(reservationDate);
      parameter.reservationDate = reservationDate.toISOString().split('T')[0];

    }

    return this.ctWebApiService.post(request, parameter);

  }

  private convertToTimeZoneOffset(date: Date) {

    const os = date.getTimezoneOffset();
    return new Date(date.getTime() - os * 60 * 1000);

  }

}
