import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RegistrationWelcomeComponent } from './registration-welcome.component';
import {MatLegacyDialogModule as MatDialogModule} from "@angular/material/legacy-dialog";

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule
  ],
  declarations: [RegistrationWelcomeComponent],
  exports:[RegistrationWelcomeComponent]
})
export class RegistrationWelcomeModule { }
