import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { AppGeneralService } from 'src/app/core/lib/app-general.service';
import { Avatar } from 'src/app/core/lib/avatar/avatar';
import { AvatarService } from 'src/app/core/lib/avatar/avatar.service';

@Component({
  selector: 'app-quiz-completed',
  templateUrl: './quiz-completed.component.html',
  styleUrls: ['./quiz-completed.component.scss']
})
export class QuizCompletedComponent implements OnInit {

  avatar?: Avatar;
  points: number = 0;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: number,
    public dialogRef: MatDialogRef<QuizCompletedComponent>,
    private _avatar: AvatarService,
    public appGeneralService: AppGeneralService
  ) {
    this.points = data;

    this._avatar
      .getUserAvatar()
      .then((result: Avatar) => this.avatar = result);
   }

  ngOnInit() {
  }

  close() {
    this.dialogRef.close();
  }

}
