<mat-form-field appearance="outline" class="w-100">

  <mat-label> {{ configuration.label }} </mat-label>
  <input matInput
         matGoogleMapsAutocomplete
         [formControl]="configuration.control"
         [placeholder]="configuration.placeholder"
         [country]="configuration.country"
         [types]="configuration.type"
         (onAutocompleteSelected)="onAutocompleteSelected($event)"
         (onLocationSelected)="onLocationSelected($event)">

</mat-form-field>
