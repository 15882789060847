import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {LoaderService} from '@ctsolution/ct-webapi';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnInit {

  public isSpinnerVisible = true;

  constructor(
    public _loadingService: LoaderService,
    private cdr: ChangeDetectorRef
  ) {

    this._loadingService
      .isLoading
      .subscribe((v) => this.isSpinnerVisible = v);

  }

  ngOnInit() {
  }

  ngAfterViewChecked(): void {

    this.cdr.detectChanges();

  }


  ngOnDestroy(): void {

    this.isSpinnerVisible = false;

  }
}
