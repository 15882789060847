import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {PartnersController} from 'src/app/core/controllers/partners-controller';
import {Category} from "../../../../core/interfaces/category";
import {IGeographicArea} from "../../../../core/interfaces/geographic-area";
import {PartnersResearchResults} from "../../../../core/interfaces/partners-research-results";
import {CategoryController} from "../../../../core/controllers/category-controller";
import {CATEGORY_TYPE} from "../../../../core/enums/category-type.enum";
import {AreaGeograficaController} from "../../../../core/controllers/area-geografica.controller";
import {map} from "rxjs/operators";
import {Observable} from "rxjs";

@Component({
  selector: 'app-affiliate-business',
  templateUrl: './affiliate-business.component.html',
  styleUrls: ['./affiliate-business.component.scss']
})
export class AffiliateBusinessComponent implements OnInit {

  form: FormGroup;

  categories: Array<Category> = [];
  areas: Array<IGeographicArea> = [];

  partners: Array<PartnersResearchResults> = [];
  filteredPartners: Observable<PartnersResearchResults[]> | undefined;

  constructor(
    private formBuilder: FormBuilder,
    private categoryController: CategoryController,
    private areaGeograficaController: AreaGeograficaController,
    private partnersController: PartnersController
  ) {

    this.form = this.formBuilder.group({
      category: new FormControl(-1),
      area: new FormControl(-1)
    });

    this.filteredPartners = this.form.valueChanges
      .pipe(map(value => this._filterMapping(value, this.partners)));

  }

  ngOnInit() {

    this.getAreas();

    this.getCategories();

    this.research();

  }

  private _filterMapping = (value: any, container: Array<PartnersResearchResults>) => {

    return container.filter((option: PartnersResearchResults) => ((value.category === option.categoriaID || value.category === -1) && (value.area === option.areaID || value.area === -1)));

  }

  private getCategories() {

    this.categoryController
      .get(CATEGORY_TYPE.PARTNER)
      .then((result: Array<Category>) => {

        this.categories = result;

        if(!this.categories.find(x => x.id == -1)){
              this.categories.push({id: -1, descrizione: 'Tutte le categorie'});
        }
      });

  }

  private getAreas() {

    this.areaGeograficaController
      .get()
      .then((list: IGeographicArea[]) => {

        this.areas = list;

        if(!this.areas.find(x => x.id == -1)){
          this.areas.push({id: -1, descrizione: 'Tutti i quartieri'});
    }
      });

  }

  private research() {

    this.partnersController
      .getAllPartners()
      .then((result: Array<PartnersResearchResults>) => {

        this.partners = result;

        this.form.setValue({category: -1, area:  -1});

      });

  }

}
