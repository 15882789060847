import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LoggedHomeComponent} from './logged-home.component';
import {CtButtonModule} from '@ctsolution/ct-framework';
import {MatIconModule} from '@angular/material/icon';
import {PersonalTotalScoreModule} from '../components/personal-total-score/personal-total-score.module';
import {MatLegacyDialogModule as MatDialogModule} from '@angular/material/legacy-dialog';
import {AuthGuardService} from 'src/app/core/lib/auth/auth-guard.service';
import {AccountController} from 'src/app/core/controllers/account-controller';
import {MissionsController} from "../../../core/controllers/missions-controller";

@NgModule({
  imports: [
    CommonModule,
    CtButtonModule,
    PersonalTotalScoreModule,
    MatIconModule,
    MatDialogModule
  ],
  declarations: [LoggedHomeComponent],
  exports: [LoggedHomeComponent],
  providers: [AuthGuardService, AccountController, MissionsController]
})
export class LoggedHomeModule {
}
