import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ConferimentoComponent} from './conferimento.component';

@NgModule({
  declarations: [
    ConferimentoComponent
  ],
  imports: [
    CommonModule
  ]
})
export class ConferimentoModule {
}
