import {Component, OnInit} from '@angular/core';
import {CtButtonParameters} from '@ctsolution/ct-framework';
import {AppGeneralService} from 'src/app/core/lib/app-general.service';
import {AuthGuardService} from "../../../core/lib/auth/auth-guard.service";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  constructor(
    private AuthService: AuthGuardService,
    private generalService: AppGeneralService) {

    if (this.AuthService.loggedUser()) {

      this.generalService.navigateTo('/home');

    }

  }

  ngOnInit() {
  }

}
