import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-ecobinder-tutorial',
  templateUrl: './ecobinder-tutorial.component.html',
  styleUrls: ['./ecobinder-tutorial.component.scss']
})
export class EcobinderTutorialComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<EcobinderTutorialComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
  }

  close() {
    this.dialogRef.close();
  }

}
