import { Component, OnInit } from '@angular/core';
import { CtButtonParameters } from '@ctsolution/ct-framework';
import { AppGeneralService } from 'src/app/core/lib/app-general.service';
import { AuthService } from "../../core/lib/auth/auth.service";
import { ExternalLinkService } from 'src/app/core/lib/external-link.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  viewModel: any = {

    buttons: this.GetButtons()

  }


  constructor(
    private appGeneralService: AppGeneralService,
    private authService: AuthService,
   private termsConditions: ExternalLinkService

  ) {
  }

  ngOnInit() {}

  GetButtons(): Array<CtButtonParameters> {
    return [
      CtButtonParameters
        .create((event) => {
          this.appGeneralService.navigateTo('/impostazioni/contatti')
        })
        .setCSS("general-button", true)
        .setMaterialIcon('contacts')
        .setContent("Contatti"),

      CtButtonParameters
        .create((event) => {
          this.appGeneralService.navigateTo('/impostazioni/modifica-profilo')
        })
        .setCSS("general-button", true)
        .setMaterialIcon('manage_accounts')
        .setContent("Modifica Profilo"),

      CtButtonParameters
        .create((event) => {
          this.appGeneralService.navigateTo('/impostazioni/modifica-password')
        })
        .setCSS("general-button", true)
        .setMaterialIcon('lock_reset')
        .setContent("Modifica Password"),

      CtButtonParameters
        .create((event) => this.termsConditions.openTermsConditions())
        .setCSS("general-button", true)
        .setMaterialIcon('cookie')
        .setContent("Condizioni Generali"),

      CtButtonParameters
        .create((event) => {
          this.authService.deleteBearerTKN()
        })
        .setCSS("general-button", true)
        .setMaterialIcon('logout')
        .setContent("Logout")

    ];

  }

}