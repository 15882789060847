import {CallResult} from '../classes/call-result';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {CtWebapiService, DataRequest} from '@ctsolution/ct-webapi';

@Injectable()
export class QuestionarioController {

  private controller: string = 'questionario';

  constructor(private ctWebApiService: CtWebapiService) {
  }

  get(): Observable<CallResult> {

    const request: DataRequest = new DataRequest(this.controller);
    return this.ctWebApiService.get(request);

  }

  getSurvey(id: number): Observable<CallResult> {

    const request: DataRequest = new DataRequest(`${this.controller}/${id}`);
    return this.ctWebApiService.get(request);

  }

  postSurvey(SurveyID: number, Survey: any): Observable<CallResult> {

    const request: DataRequest = new DataRequest(`${this.controller}/`);

    return this.ctWebApiService.post(request, {
      IdQuestionario: SurveyID,
      answersForQuestions: Survey,
    });

  }

}
