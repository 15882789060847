<div class="medals-cnt mb-5">

  <div class="medals">

    <ng-container *ngFor="let level of [].constructor(6); let i =index;">

      <div class="single-medal" [class.active]="(i + 1) === userLevel">

        <img [src]="['assets/images/levels-ecoxxi/star_'+ (i +1) + ( userLevel < (i +1) ? '_off' : '')+'.png']" />

      </div>

    </ng-container>

  </div>

</div>
