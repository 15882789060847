<div class="eco-21-cntr edit-avatars-cntr pb-5">

  <title-heading [titleHeadingViewModel]="{ title: 'Profilo'}"></title-heading>

  <div class=" h-100">

    <div class="avatarsChoiceCntr">

      <div class="row">
        
        <div class="col-4 col-md-4" (click)="changeAvatar(avatar)" *ngFor="let avatar of avatars">

          <div class="d-flex w-100 mt-3">

            <div class="eco21-avatar m-auto" [class.disabled]="avatar.level > userLevel" [ngStyle]="{ 'background-image': 'url('+ getAvatarPathImage(avatar) + ')'}"></div>

          </div>

          <div class="text-center">

            <!-- <h3>{{avatar.name}}</h3> -->

            <h4>Livello: <span class="font-weight-bolder dark-green">{{avatar.level}}</span></h4> 

          </div>
          
        </div>

    </div>

  </div>

</div>