import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfileComponent } from './profile.component';
import { FamiliarComponent } from './familiar/familiar.component';
import { AvatarsComponent } from './avatars/avatars.component';
import { CtButtonModule } from '@ctsolution/ct-framework';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { TitleHeadingModule } from 'src/app/components/title-heading/title-heading.module';
import { AddFamilyMemberComponent } from './familiar/add-family-member/add-family-member.component';
import { FormInputModule } from 'src/app/components/form-input/form-input.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { AvatarController } from 'src/app/core/controllers/avatars-controller';
import { FamiliarController } from 'src/app/core/controllers/familiar-controller';
import { FamiliarRegistrationCompleteComponent } from './familiar/familiar-registration-complete/familiar-registration-complete.component';

@NgModule({
  imports: [
    CommonModule,
    TitleHeadingModule,
    CtButtonModule,
  
    FormInputModule,
    FormsModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    MatCheckboxModule
  ],
  declarations: [
    ProfileComponent,
     FamiliarComponent, 
     AvatarsComponent,
     AddFamilyMemberComponent,
     FamiliarRegistrationCompleteComponent
    ],
  exports: [
    ProfileComponent, 
    FamiliarComponent, 
    AvatarsComponent,
    AddFamilyMemberComponent,
    FamiliarRegistrationCompleteComponent  
  ],
  providers: [AvatarController, FamiliarController]
})
export class ProfileModule { }
