<div class="registration-welcome-cntr">

  <h1 text-center dark-green mat-dialog-title>GRAZIE PER ESSERTI ISCRITTO</h1>
<div mat-dialog-content>
  <p class="text-center ml-3 mr-3">
    Esplora la nostra App e scopri come riciclare al meglio la plastica!
    <br/>
    <small>Dovresti aver ricevuto un'e-mail all'indirizzo con cui ti sei registrato.<br/>Clicca sul link per confermare
      la registrazione.</small>
  </p>
  <div class="clearfix">
    <img src="assets/images/icons/cuori.png" class="float-right" height="50" />
  </div>
</div>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close>Chiudi</button>
</div>

</div>

