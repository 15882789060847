import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LoginComponent} from './login.component';
import {LoginFormComponent} from './login-form/login-form.component';
import {CtButtonModule} from '@ctsolution/ct-framework';
import {TitleHeadingModule} from 'src/app/components/title-heading/title-heading.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatLegacyFormFieldModule as MatFormFieldModule} from '@angular/material/legacy-form-field';
import {MatLegacyCheckboxModule as MatCheckboxModule} from '@angular/material/legacy-checkbox';
import {MatLegacyInputModule as MatInputModule} from '@angular/material/legacy-input';
import {FormInputModule} from 'src/app/components/form-input/form-input.module';

@NgModule({
  imports: [
    CommonModule,
    TitleHeadingModule,
    CtButtonModule,
    FormInputModule,
    FormsModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    MatCheckboxModule
  ],
  declarations: [
    LoginComponent,
    LoginFormComponent
  ],
  exports: [
    LoginComponent,
    LoginFormComponent]
})
export class LoginModule {
}
