import {Injectable} from '@angular/core';
import {RankingGetParameter} from '../classes/ranking-get-parameter';
import {Utente} from "../../pages/rank/rank.component";
import {CtWebapiService, DataRequest} from "@ctsolution/ct-webapi";

@Injectable()
export class RankController {

  private controller: string = 'Ranking';

  constructor(private ctWebApiService: CtWebapiService) {
  }

  get(parameter: RankingGetParameter): Promise<Array<Utente>> {

    const request: DataRequest = new DataRequest(`${this.controller}`, parameter);

    return new Promise<Array<Utente>>((resolve) => {

      this.ctWebApiService
        .get(request)
        .subscribe((result: Array<Utente>) => resolve(result));

    });

  }

}
