<div class="accumulated-balance-cntr">

    <p class="text-center primary-color">SALDO ACCUMULATO &nbsp;

        <span class="font-weight-bolder light-green">{{balance}}</span>
        
    </p>

</div>


