<div class="login-form-cntr">

  <div class="container h-100">

    <form [formGroup]="form">

        <form-input [parameters]="email"></form-input>

        <div class="mb-1">

          <form-input [parameters]="password"></form-input>

        </div>

        <div class="d-flex mb-5">

          <small class="primary-color">Hai dimenticato la Password?</small> &nbsp;

          <small (click)="goToRecoveryPassword()"><strong class="link light-green font-weight-light text-decoration-underline">Clicca qui per recuperarla</strong></small>

        </div>

        <div class="mt-2">

          <mat-checkbox class="me-3" (change)="changePasswordType($event)">Mostra la Password</mat-checkbox>
    
        </div>

        <!-- Check di rememberMe -->

        <!-- <div class="mt-2">
    
          <mat-checkbox formControlName="rememberMe" class="me-3">Ricorda i miei dati</mat-checkbox>
    
        </div>  -->

      <div class="mt-4 m-auto text-center">
  
        <ct-button [parameters]="loginButton"></ct-button>
    
      </div>  
  
    </form>

  </div>

</div>
