import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MapsService {

  zoom = 14;

  lastLocation: ILatLng; // Via Gabriele D'Annunzio
  readonly lastLocationKey: string = 'lst_location_ck';

  constructor() {

    this.lastLocation = this.getLastSearchedLocation();

  }

  saveLastSearchedLocation(place_name: string, latitude: number, longitude: number): void {

    if (this.lastLocation.latitude !== latitude || this.lastLocation.longitude !== longitude) {

      this.lastLocation.placename = place_name;
      this.lastLocation.latitude = latitude;
      this.lastLocation.longitude = longitude;

      const str_last_location = JSON.stringify(this.lastLocation);

      localStorage.setItem(this.lastLocationKey, str_last_location);

    }

  }

  getLastSearchedLocation(): ILatLng {

    const stored_last_location: string | null = localStorage.getItem(this.lastLocationKey);

    if (stored_last_location !== null && stored_last_location !== '') {

      return JSON.parse(stored_last_location) as ILatLng;

    }

    return {latitude: 44.4050588, longitude: 8.9355349, placename: 'Genova'};

  }

}

export interface ILatLng {

  latitude: number;
  longitude: number;
  placename?: string;

}
