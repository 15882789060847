<div class="eco-21-cntr affiliate-business-cntr">

  <title-heading
    [titleHeadingViewModel]="{ title: 'Attività affiliate', subtitle: 'Usufruisci degli sconti acquistando i Coupon', classSubtitle: 'partners-subtitle'}"></title-heading>

  <div class="row container h-100 m-auto">

    <form class="news-research-cntr pl-5 pr-5" [formGroup]="form">

      <app-select-option
        [selectOptionViewModel]="{control: form.get('area'), label: 'Seleziona il tuo quartiere', types: areas}"></app-select-option>

      <app-select-option
        [selectOptionViewModel]="{control: form.get('category'), label: 'Filtra per categoria', types: categories}"></app-select-option>

    </form>

    <ng-container *ngFor="let partner of filteredPartners | async">

      <div class="d-flex partners-container b-radius mt-4">



        <app-card [cardBusiness]="partner"></app-card>

        <button class="general-button" [routerLink]="['partner', partner.id]">Riscatta Coupon</button>

      </div>

    </ng-container>

    <!-- //TODO Marghe: aggiungere template se non ci sono elementi -->

  </div>

</div>
