import {Component, Input, OnInit} from '@angular/core';
import {CtCardParameters} from '@ctsolution/ct-framework';
import {BindersResearchResults} from "../../core/interfaces/binders-research-results";
import {PartnersResearchResults} from "../../core/interfaces/partners-research-results";

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit {

  /**
   * TODO: non era meglio prendere una variabile sola in input, che poteva avere tipo BindersResearchResults e PartnersResearchResults ?
   * Es. @Input() cardConfiguration: BindersResearchResults |  PartnersResearchResults;
   * In modo da poterne poi centralizzare la configurazione.
   **/

  @Input() card!: BindersResearchResults;
  @Input() cardBusiness!: PartnersResearchResults;

  cardParameter!: CtCardParameters;

  constructor() {
  }

  ngOnInit() {

    if (this.card) {

      this.setCardContent();

    }

    if (this.cardBusiness) {

      this.setBusinessCardContent();

    }

  }

  setBusinessCardContent = () => {

    const content =
      `<hr>
      <h3>${this.cardBusiness.categoria}<br\><\h3>
      <h6>${this.cardBusiness.indirizzo}<br\><\h6>
      <p>${this.cardBusiness.punti}<\p>`;

    this.cardParameter = CtCardParameters
      .create(event => {
      })
      .setTitle(this.cardBusiness.nome)
      .setText(content);

  }

  setCardContent = () => {

    let content = "";
    if(this.card.note){
     content = `${this.card.note}<br\>${this.card.indirizzo}`;
    }else{

      content = `${this.card.indirizzo}`;
    }
    this.cardParameter = CtCardParameters
      .create(event => {
      })
      .setTitle(this.card.nome)
      .setText(content)
      .setBackgroundClass('ct-card-image');

  }


}
