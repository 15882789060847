<div class="eco-21-cntr targets-cntr h-100">

  <title-heading
    [titleHeadingViewModel]="{ title: 'Missioni', subtitle: 'Svolgi le missioni per sbloccare fantastici premi', classSubtitle:'mb-5'}">
  </title-heading>


  <ng-container *ngIf="targets.length > 0; else noTargetTemplate">

  <div class="targets-item pt-3 pb-3" [ngClass]="{'odd': i % 2 == 0, 'locked': !target.attivo}"
       *ngFor="let target of targets; let i = index;">

       

    <div class="container h-100 mb-5">

      <div class="row ms-0 me-0">

        <div class="col-2 d-flex">

          <img class="medal-img m-auto"
               [src]="['assets/images/levels-ecoxxi/star_'+ target.livello + ( userLevel < +target.livello || !target.attivo || target.completamento < 100 ? '_off' : '')+'.png']"/>

        </div>

        <div class="col-7 pt-2 pb-2">

          <div class="font-weight-bolder">{{target.grado}}</div>

          <div>{{target.descrizione}}</div>

          <div *ngIf="userLevel < +target.livello && !target.attivo">

            <div class="pt-2 d-flex align-content-center">

              <mat-icon class="lock-icon">lock</mat-icon>
              <p class="text-left">Completa le missioni precedenti</p>

            </div>

          </div>

        </div>

        <div class="col-3 d-flex" *ngIf="target.completamento > 0">

          <div class="m-auto chart">
            <canvas baseChart [colors]="chartColors" [data]="getDonutDataSet(target.completamento)" [chartType]="ChartType" [options]="chartOptions"></canvas>
          </div>

        </div>

      </div>

    </div>

  </div>
  
</ng-container>


<ng-template #noTargetTemplate>

  <p class="text-center">Le missioni non sono al momento disponibili</p>

</ng-template>

</div>
