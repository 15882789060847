
<div class="quiz-cntr container h-100">
  
      <title-heading [titleHeadingViewModel]="{ title: 'Quanto ne sai?', subtitle: 'QUIZ'}"></title-heading>

  <ng-container *ngIf="Questionario">

    <div class="quiz-items-cntr">

        <h2 class="m-3"> {{Questionario.nome}}</h2>

        <form [formGroup]="SurveyForm" (ngSubmit)="SendForm()">

          <ol>
            <li class="mb-3" *ngFor="let question of Questionario.domande">
              <div class="mb-1 question font-weight-medium">
                {{question.testo}}
              </div>
              <div class="ml-2">
                <mat-radio-group [formControlName]="question.id">
                  <ng-container *ngFor="let answer of question.risposte">
                    <mat-radio-button class="mr-3" [value]="answer.id">{{answer.testo}}</mat-radio-button>
                    <br />
                  </ng-container>
                </mat-radio-group>
                <!-- <mat-error *ngIf="FormSubmitted && SurveyForm.get(question.id.toString()).hasError('required')">
                  <small>Selezionare un <strong>opzione</strong></small>
                </mat-error> -->
              </div>
            </li>
          </ol>

          <div class="text-center" *ngIf="!SurveyEdit">

            <button class="general-button" type="submit">FINE</button>

          </div>

        </form>

    </div>

  </ng-container>

</div>