import {CallResult} from './../classes/call-result';
import {Observable} from 'rxjs';
import {AppGeneralService} from '../lib/app-general.service';
import {Injectable} from '@angular/core';
import {CtWebapiService, DataRequest} from '@ctsolution/ct-webapi';
import {BindersResearch} from '../classes/binders-research';

@Injectable()
export class BindersController {

  private controller: string = 'risorsa';

  constructor(
    public AppGeneralService: AppGeneralService,
    private ctWebApiService: CtWebapiService) {
  }

  get(id: number): Observable<CallResult> {

    const request: DataRequest = new DataRequest(`${this.controller}/${id}`);
    return this.ctWebApiService.get(request);

  }

  research(MacroTypeRisorsa: string, latitudine?: number, longitudine?: number, giorno?: number): Observable<CallResult> {

    const bindersResearchParams = new BindersResearch(MacroTypeRisorsa, latitudine, longitudine, giorno);
    const request: DataRequest = new DataRequest(this.controller);

    return this.ctWebApiService
      .post(request, bindersResearchParams);

  }

}
