<div class="form-input-cntr" [formGroup]="parameters.formGroup">

  <ng-container *ngIf="parameters && parameters.controlName">

    <mat-form-field class="p-1 w-100" appearance="outline">

      <mat-label> {{parameters.label }} </mat-label>

      <input 
      [type]="parameters.type ?? 'text'"      
      matInput
      class="form-control"
      [formControlName]="parameters.controlName"
      autocomplete="text-input-off" 
      [readonly]="parameters.readonly"
      [pattern]="parameters.pattern ?? ''"
      [class]="parameters.cssStyleInput" 
      [placeholder]="parameters.placeholder ?? ''"
       [required]="parameters.isRequired" 
       (ngModelChange)="change.emit()" 
       (blur)="blur.emit()"
       >

      <mat-hint *ngIf="parameters.hint" [ngClass]="parameters.cssStyleHint">{{parameters.hint}}</mat-hint>


       <mat-error *ngIf="this.checkMatErrorGeneric()" [innerHTML]="this.getErrorMessage()"></mat-error>

    </mat-form-field>

  </ng-container>

</div>