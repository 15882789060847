import {CallResult} from './../classes/call-result';
import {Observable} from 'rxjs';
import {AppGeneralService} from '../lib/app-general.service';
import {Injectable} from '@angular/core';
import {CtWebapiService, DataRequest} from '@ctsolution/ct-webapi';
import {Category} from "../interfaces/category";
import {ICallResult} from "../interfaces/Icall-result";

@Injectable({providedIn: 'root'})
export class CategoryController {

  private categoriesList: Array<Category> = []
  private controller: string = 'categoria';

  constructor(
    public AppGeneralService: AppGeneralService,
    private ctWebApiService: CtWebapiService
  ) {
  }

  get(type: string): Promise<Array<Category>> {

    return new Promise<Array<Category>>((resolve) => {

      if (this.categoriesList.length > 0) {

        resolve(this.categoriesList);
        return;

      }

      const request: DataRequest = new DataRequest(`${this.controller}/${type}`);

      this.ctWebApiService
        .get(request)
        .subscribe((result: ICallResult) => {

          this.categoriesList = result.result;
          resolve(this.categoriesList);

        })

    });

  }

}
