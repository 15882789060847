import {Component, OnInit} from '@angular/core';
import { AppGeneralService } from 'src/app/core/lib/app-general.service';
import {QuestionarioController} from "../../../core/controllers/questionario-controller";

@Component({
  selector: 'app-available-quiz',
  templateUrl: './available-quiz.component.html',
  styleUrls: ['./available-quiz.component.scss'],
  providers: [QuestionarioController]
})
export class AvailableQuizComponent implements OnInit {

  questionari: Array<{ id: number, nome: string, descrizione: string }> = [];

  constructor(
    private questionarioController: QuestionarioController,
    public appGeneralService: AppGeneralService
    ) {
  }

  getQuestionari() {

    this.questionarioController
      .get()
      .subscribe((result: any) => {

        this.questionari = result['result'];

      });

  }

  ngOnInit() {

     this.getQuestionari();

  }

}
