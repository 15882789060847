import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-select-option',
  templateUrl: './select-option.component.html',
  styleUrls: ['./select-option.component.scss']
})
export class SelectOptionComponent implements OnInit {

  @Input() selectOptionViewModel!: ISelectOption;

  @Output() change: EventEmitter<any> = new EventEmitter<any>();
  @Output() blur: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

}

export interface ISelectOption{

  control: any;
  label?: string;
  types?: Array<ISelectType>;
  
}

export interface ISelectType{

  id?: any;
  descrizione?: any;
  place?: any;

}


