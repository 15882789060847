import {AuthService} from '../lib/auth/auth.service';
import {Router} from '@angular/router';
import {Injectable} from '@angular/core';
import {CtWebapiService, DataRequest} from '@ctsolution/ct-webapi';
import {Login} from '../classes/login';
import {MissionsController} from "./missions-controller";
import { ICallResult } from '../interfaces/Icall-result';


@Injectable()
export class AccountController {

  private controller: string = 'account';

  constructor(
    private router: Router,
    private AuthService: AuthService,
    private MissionsController: MissionsController,
    private ctWebApiService: CtWebapiService) {
  }

  login(Username: string, Password: string, Registration: boolean = false, returnUrl: string | null): void {

    const loginParams = new Login(Username, Password);
    const request: DataRequest = new DataRequest(this.controller);

    this.ctWebApiService
      .post(request, loginParams)
      .subscribe((result) => {

        this.AuthService.setBearerToken(result['token']);

        if (returnUrl) {

          this.router.navigateByUrl(returnUrl);

        } else {

          this.router
            .navigate(['/'])
            .then(() => {

              this.MissionsController.update().subscribe(() => this.refreshClaims(Registration));

            });

        }

      });

  }

  delete(): Promise<ICallResult>{

    return new Promise<ICallResult>((resolve)=> {

      const request: DataRequest = new DataRequest(this.controller);

      this.ctWebApiService
      .delete(request)
      .subscribe((request: ICallResult) => {
        resolve(request);

      });

    });

  }


  refreshClaims(Registration: boolean = false): void {

    const request: DataRequest = new DataRequest(this.controller, {hideSpinner: true});

    this.ctWebApiService
      .put(request, {})
      .subscribe((result) => {

        this.AuthService.setBearerToken(result['token']);

        if (!Registration && !(this.AuthService.getEmailConfirmed())) {

          /**TODO: valutare il reintegro di questo component. Inizialmente era necessario per dire all'utente di inserire il codice TARI,
           * servirà anche per la Sicilia? informarsi. Ricordava inoltre di confermare la mail. Ma a sto punto potrebbe bastare una breadcrumb?
           **/
          // this.MatDialog.open(RememberModalComponent, {
          //   width: '350px',
          //   data: {
          //     NeedsTariCode: !this.AuthService.getTariCodeConfirmed(),
          //     NeedsMailConfirm: !this.AuthService.getEmailConfirmed()
          //   },
          //   panelClass: 'plastipremia-dialog'
          // });
          //
        }

      });

  }

}
