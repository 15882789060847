import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { CtButtonParameters } from '@ctsolution/ct-framework';
import { PasswordStrengthValidator } from 'src/app/authentication/password-strength.validator';
import { FormInputParameters } from 'src/app/components/form-input/class/form-input-parameters';
import { FormInputValidationStandard, FormInputValidationValue } from 'src/app/components/form-input/class/form-input-validation';

import { PasswordRecovery } from 'src/app/core/classes/password-recovery';
import { PasswordController } from 'src/app/core/controllers/password-controller';
import { AppGeneralService } from 'src/app/core/lib/app-general.service';

export function CTMatchFieldValidator(controllerMatching1: UntypedFormControl, controllerMatching2: UntypedFormControl): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    return controllerMatching1.value === controllerMatching2.value ? null : {CTMatchFieldValidator: true};
  };
}

@Component({
  selector: 'app-edit-password',
  templateUrl: './edit-password.component.html',
  styleUrls: ['./edit-password.component.scss']
})
export class EditPasswordComponent implements OnInit {

  form: UntypedFormGroup = new UntypedFormGroup({});

    
  password: FormInputParameters = FormInputParameters
  .create('password',this.form)
  .setLabel('Password corrente')
  .setRequired()
  .addValidator(FormInputValidationStandard.Required())
  .addValidator(FormInputValidationStandard.MinLength(6));

  passwordNew: FormInputParameters = FormInputParameters
  .create('passwordNew', this.form)
  .setLabel('Inserisci la nuova password')
  .setRequired()
  .setHint('*La password deve essere composta da almeno 6 caratteri, contenere una lettera maiuscola, una minuscola e un numero')
  .addValidator(FormInputValidationStandard.Required())
  .addValidator(FormInputValidationStandard.MinLength(6))
  .addValidator(FormInputValidationValue.Create("passwordStrength",PasswordStrengthValidator,"Deve contenere una lettera maiuscola,una lettera minuscola e un numero"));

  passwordConfirm: FormInputParameters = FormInputParameters
  .create('passwordConfirm', this.form)
  .setRequired()
  .setLabel('Conferma la nuova password')
  .addValidator(FormInputValidationStandard.Required());



  editButton: CtButtonParameters = CtButtonParameters
  .create((event) => { this.update() })
  .setCSS("general-button", true)
  .setContent("Salva")




  constructor(
    private appGeneralService: AppGeneralService,
    private passwordController: PasswordController,
    private matSnackBar: MatSnackBar
  ) {

    //, this.checkPasswords()

    this.form.addValidators(CTMatchFieldValidator(this.passwordNew.formControl, this.passwordConfirm.formControl));
    
  }

  ngOnInit() {
  }


  onMatchErrorFormGroup():boolean{
    return this.form.errors && this.form.errors['CTMatchFieldValidator'];
  }

  update() {
    if (this.form.valid) {
      const PasswordRecoveryParam = new PasswordRecovery(
        this.form.get('password')?.value,
        this.form.get('passwordNew')?.value
      )
      this.passwordController.post(PasswordRecoveryParam).subscribe(() => {
        this.matSnackBar.open('Password aggiornata', 'X', {
          duration: 2000
        });
        this.appGeneralService.back();
      });
    }
  }

  
  checkPasswords(group: UntypedFormGroup) { // here we have the 'passwords' group
    let pass = group.get('passwordNew')?.value;
    let confirmPass = group.get('passwordConfirm')?.value;

    return pass === confirmPass ? null : { notSame: true }
  }

}
