<div class="points-balance-cntr">

  <div class="d-flex justify-content-center pt-4 mb-2">

    <div class="score-title font-weight-bolder text-uppercase">Saldo punti</div>
    <span class="font-weight-bolder score">{{balance}}</span>

  </div>

  <div class="d-flex justify-content-center pb-1">

    <div class="score-content text-uppercase">Prossimo aggiornamento punti alle <span
      class="hour">{{nextCheckDateString}}</span></div>

  </div>

</div>
