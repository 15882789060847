<div class="profile-cntr eco-21-cntr h-75">

  <div class="container h-100">
   
    <title-heading [titleHeadingViewModel]="{ title: 'Profilo'}"></title-heading>

    <div class="profile-section" *ngIf="avatar">

      <div class="profile-avatar" [ngStyle]="{'background': 'url('+ avatar.getFullPath() +')'}">

        <img class="stemma mb-3" src="assets/images/levels-ecoxxi/star_{{userLevel}}.png" />

      </div>

    </div>

        <div class="profile-description h-100">

            <div class="name font-weight-bolder dark-green text-start">{{loggedUser.Username}}</div>

            <p class="primary-color font-weight-bolder">LIVELLO {{userLevel}}</p>

            <ng-container *ngFor="let button of viewModel.buttons">

              <div class="mt-2 mb-3 w-50">
        
                <ct-button [parameters]="button"></ct-button>
        
              </div>
        
            </ng-container>      

        </div>     

  </div>

</div>