import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StringService {

  constructor() {
  }

  capitalize = (word: string) => word[0].toUpperCase() + word.substr(1).toLowerCase();

}
