import {Injectable} from '@angular/core';
import {StringService} from './string.service';
import {DAY} from '../enums/day';

@Injectable({
  providedIn: 'root'
})
export class DateService {

  private Days: any = DAY;

  constructor(
    private stringService: StringService
  ) {
  }

  getCurrentDay() {

    const Day = new Date().toLocaleString('it-IT', {weekday: 'long'});
    return this.Days[this.stringService.capitalize(Day)];

  }

  convertCustomDate(date: string) {

    const SplittedDate = date.split('/');
    return SplittedDate[2] + '-' + SplittedDate[1] + '-' + SplittedDate[0];

  }

}
