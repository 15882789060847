import { Component, OnDestroy, OnInit } from '@angular/core';
import { BalanceController } from 'src/app/core/controllers/balance-controller';

@Component({
  selector: 'app-personal-total-score',
  templateUrl: './personal-total-score.component.html',
  styleUrls: ['./personal-total-score.component.scss']
})
export class PersonalTotalScoreComponent implements OnInit, OnDestroy {

  balance: number = 0;

  nextCheckDate?: Date;
  nextCheckDateString: any = '';

  timeout?: any;

  constructor(private balanceController: BalanceController) {
  }

  ngOnInit() {

    this.getBalance();

  }

  getBalance() {

    this.balanceController
      .get()
      .subscribe((result: any) => {

        this.balance = result?.result?.points ?? 0;

        this.nextCheckDate = this.getNextCheckDate(result?.result?.nextCheckDate);
        this.nextCheckDateString = (result?.result?.nextCheckDate ?? '').split(' ')[1];

        let ms = this.nextCheckDate.getTime() - Date.now();

        // if nextCheckDate < della data attuale (casistica rara) setTimeout 10 minuti dopo
        if (ms < 0) {

          ms = 600000;

        }

        if (ms > 0) {

          this.timeout = setTimeout(() => this.getBalance(), ms);

        }

      });

  }
 

  getNextCheckDate(date: any): Date {

    const splittedDateTime = date.split(' ');
    const splittedDate = splittedDateTime[0].split('/');
    const splittedTime = splittedDateTime[1].split(':');
    return new Date(splittedDate[2], splittedDate[1] - 1, splittedDate[0], splittedTime[0], splittedTime[1]);

  }

  ngOnDestroy() {

    if (this.timeout) {

      clearTimeout(this.timeout)

    }

  }

}


