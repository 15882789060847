import {Component, OnInit} from '@angular/core';
import { UntypedFormGroup, Validators} from '@angular/forms';
import {CtButtonParameters} from '@ctsolution/ct-framework';
import {AppGeneralService} from 'src/app/core/lib/app-general.service';
import {ContactData} from "../../../core/classes/contact-data";
import {ContactsController} from "../../../core/controllers/contatti-controller";
import {SnackbarService} from "../../../core/lib/snackbar.service";
import { FormInputParameters } from 'src/app/components/form-input/class/form-input-parameters';
import { FormInputValidationStandard, FormInputValidationValue } from 'src/app/components/form-input/class/form-input-validation';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss'],
  providers: [ContactsController]
})
export class ContactsComponent implements OnInit {

  form: UntypedFormGroup = new UntypedFormGroup({});

  name: FormInputParameters = FormInputParameters
  .create('name',this.form)
  .setLabel('Nome')
  .addValidator(FormInputValidationStandard.Required());

  email: FormInputParameters = FormInputParameters
  .create('email',this.form)
  .setLabel('Email')
  .addValidator(FormInputValidationStandard.Required())
  .addValidator(FormInputValidationValue.Create("email",Validators.email,"La mail non è valida"));


  message: FormInputParameters = FormInputParameters
  .create('message',this.form)
  .setLabel('*Scrivi il tuo messaggio')
  .addValidator(FormInputValidationStandard.Required())
  .setType('text');


  // .addValidator(FormInputValidationValue.Create("userEmail",this.authService.getUserEmail(),"Il Nickname non può contenere caratteri speciali"));

  addButton: CtButtonParameters = CtButtonParameters
    .create((event) => this.contact())
    .setCSS("general-button", true)
    .setContent("Invia")

  constructor(
    private appGeneralService: AppGeneralService,
    private contactsController: ContactsController,
    private snackbarService: SnackbarService
  ) {

  }

  ngOnInit() {
  }

  contact() {

    if (this.form.valid) {

      const Data = new ContactData(
        this.form.value.email,
        this.form.value.name,
        this.form.value.message
      )

      this.contactsController
        .post(Data)
        .subscribe(() => {

          this.snackbarService.openGenericSnackBar('Messaggio inviato!');
          this.appGeneralService.back();

        });

    }

  }

}
