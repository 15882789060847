import {CallResult} from './../classes/call-result';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {CtWebapiService, DataRequest} from '@ctsolution/ct-webapi';
import {ContactData} from '../classes/contact-data';

@Injectable()
export class ContactsController {

  private controller: string = 'contatti';

  constructor(private ctWebApiService: CtWebapiService) {
  }

  post(ContactData: ContactData): Observable<CallResult> {

    const request: DataRequest = new DataRequest(this.controller, {hideSpinner: true});
    return this.ctWebApiService.post(request, ContactData);

  }

}
