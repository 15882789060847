import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RecoveryPasswordComponent} from "./recovery-password.component";
import {TitleHeadingModule} from "../../components/title-heading/title-heading.module";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {FormInputModule} from "../../components/form-input/form-input.module";
import {CtButtonModule} from "@ctsolution/ct-framework";

@NgModule({
  declarations: [RecoveryPasswordComponent],
  imports: [
    CommonModule,
    TitleHeadingModule,
    FormsModule,
    ReactiveFormsModule,
    FormInputModule,
    CtButtonModule
  ],
  exports: [RecoveryPasswordComponent]
})
export class RecoveryPasswordModule {
}
