import {Injectable} from '@angular/core';
import {CallResult} from '../classes/call-result';
import {Observable} from 'rxjs';
import {CtWebapiService, DataRequest} from '@ctsolution/ct-webapi';
import {PasswordRecovery} from '../classes/password-recovery';
import {PasswordReset} from '../classes/password-reset';

@Injectable()
export class PasswordController {

  private controller: string = 'password';

  constructor(private ctWebApiService: CtWebapiService) {
  }

  post(PasswordRecovery: PasswordRecovery): Observable<CallResult> {

    const request: DataRequest = new DataRequest(this.controller);
    return this.ctWebApiService.post(request, PasswordRecovery);

  }

  reset(PasswordReset: PasswordReset) {

    const request: DataRequest = new DataRequest(this.controller);
    return this.ctWebApiService.put(request, PasswordReset);

  }

  recover(email: string) {

    const request: DataRequest = new DataRequest(this.controller, {email});
    return this.ctWebApiService.get(request);
    //TODO : prima veniva passata alla get PasswordReset capire se da errore o non è piu neccessaria

  }

}
