import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserCardComponent } from './user-card.component';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';

@NgModule({
  imports: [
    CommonModule,
    MatCardModule
  ],
  declarations: [UserCardComponent],
  exports:[UserCardComponent]
})
export class UserCardModule { }
