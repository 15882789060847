import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { CtButtonParameters } from '@ctsolution/ct-framework';
import { FormInputParameters } from 'src/app/components/form-input/class/form-input-parameters';
import { FormInputValidationStandard, FormInputValidationValue } from 'src/app/components/form-input/class/form-input-validation';
import { FamiliarData } from 'src/app/core/classes/familiar-data';

import { FamiliarController } from 'src/app/core/controllers/familiar-controller';
import { AppGeneralService } from 'src/app/core/lib/app-general.service';

import { AvatarService } from 'src/app/core/lib/avatar/avatar.service';
import { ValidatorService } from 'src/app/core/lib/validator.service';

@Component({
  selector: 'app-add-family-member',
  templateUrl: './add-family-member.component.html',
  styleUrls: ['./add-family-member.component.scss']
})
export class AddFamilyMemberComponent implements OnInit {

  form: UntypedFormGroup = new UntypedFormGroup({});

  name: FormInputParameters = FormInputParameters
  .create('name',this.form)
  .setLabel('Nome')
  .addValidator(FormInputValidationStandard.Required())  
  .addValidator(FormInputValidationStandard.MinLength(5))
  .addValidator(FormInputValidationStandard.MaxLength(20))
  .addValidator(FormInputValidationValue.Create("pattern",Validators.pattern(/^\S*$/),"Il Nickname non può contenere caratteri speciali"))

  
  email: FormInputParameters = FormInputParameters
  .create('email',this.form)
  .setLabel('Email')
  .addValidator(FormInputValidationStandard.Required())
  .addValidator(FormInputValidationValue.Create("email",Validators.email,"La mail non è valida"));

  fiscalCode: FormInputParameters = FormInputParameters
  .create('fiscalCode',this.form)
  .setLabel('Codice Fiscale')
  .addValidator(FormInputValidationStandard.Required())
  .addValidator(FormInputValidationValue.Create("FiscalCode",this.validatorService.fiscalCode,"Il Codice Fiscale non è valido"));



  familiars: Array<{ avatar: number, name: string }> = []


  addButton: CtButtonParameters = CtButtonParameters
    .create((event) => {this.save()})
    .setCSS("general-button", true)
    .setContent("Invita")


  constructor(

    private appGeneralService: AppGeneralService,
    private validatorService: ValidatorService,
    private FamiliarController: FamiliarController,
    private _snackbar: MatSnackBar,
    private _avatar: AvatarService
  ) {

    this.FamiliarController.getFamiliars().subscribe((result:any) => {
      this.familiars = result['result']['familiari'];
    });


  }

  ngOnInit() {}
 
   save() {

    if (this.form.valid) {

      const familiar = new FamiliarData(
        this.form.get('name')?.value,
        this.form.get('email')?.value,
        this.form.get('fiscalCode')?.value
      );

       this.FamiliarController.addFamiliar(familiar).subscribe((result) => {
         this._snackbar.open(result['message'], 'X', {
           duration: 2000
         });
         this.appGeneralService.back();
       });
     }

   }


  getAvatarPathImage(code:number):string {

   const avatar = this._avatar.getAvatarByCode(code);

    if (avatar !== undefined && avatar !== null) {

      return avatar.getFullPath();

    }

    return 'assets/images/avatars-ecoxxi/procione.svg';

  }

}
