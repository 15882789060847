<div class="footer-cntr general-background-color p-1">

  <mat-selection-list #menu [multiple]="false">

    <div class="d-flex">

      <mat-list-option *ngFor="let button of buttons, let i = index" [selected]="button && i == 0">    

          <button class="general-button" [routerLink]="button.path">

            <mat-icon>

              {{button.icon}}

            </mat-icon>

          </button>

      </mat-list-option>

    </div>

  </mat-selection-list>
  
</div>