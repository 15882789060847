import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'title-heading',
  templateUrl: './title-heading.component.html',
  styleUrls: ['./title-heading.component.scss']
})
export class TitleHeadingComponent implements OnInit {

  @Input() titleHeadingViewModel: ITitleHeading  | undefined;

  viewModel ={

    isBackButton: false

  }

  

  constructor(private cdref: ChangeDetectorRef) { }

  ngOnInit() {
  }

  ngAfterViewInit() {

    this.cdref.detectChanges();

  }

  ngAfterViewChecked() {

    this.cdref.detectChanges();

  }

}

export interface ITitleHeading {

  title: string;
  subtitle?: string;

  class?: string;
  classSubtitle?: string;

}

