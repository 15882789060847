import { Component, Input, OnInit } from '@angular/core';
import { Utente } from '../rank.component';
import {AvatarService} from "../../../core/lib/avatar/avatar.service";
import {Avatar} from "../../../core/lib/avatar/avatar";

@Component({
  selector: 'app-user-card',
  templateUrl: './user-card.component.html',
  styleUrls: ['./user-card.component.scss']
})
export class UserCardComponent implements OnInit {

  @Input() user! : Utente;
  @Input() position! : number

  avatar?: Avatar;

  constructor(
    private _avatar: AvatarService
  ) { }

  ngOnInit() {

    this.avatar = this._avatar.getAvatarByCode(+this.user.avatarCode);

  }

}
