import {Injectable} from '@angular/core';
import {CtWebapiService, DataRequest} from '@ctsolution/ct-webapi';
import {COUPON_TYPE} from '../enums/coupon-type.enum';

@Injectable()
export class DisponibilitaController {

  private controller: string = 'DisponibilitaCoupon';

  constructor(private ctWebApiService: CtWebapiService) {
  }

  getAvailability(CouponType: COUPON_TYPE) {

    const integration: string = 'integrationCode';

    const request: DataRequest = new DataRequest(this.controller, {integration, CouponType, hideSpinner: true});
    return this.ctWebApiService.get(request);

  }

}
