import {CallResult} from './../classes/call-result';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {CtWebapiService, DataRequest} from '@ctsolution/ct-webapi';

@Injectable()
export class MissionsController {

  private controller: string = 'missione';

  constructor(private ctWebApiService: CtWebapiService) {
  }

  get(): Observable<CallResult> {

    const request: DataRequest = new DataRequest(this.controller);
    return this.ctWebApiService.get(request);

  }

  update(): Observable<CallResult> {

    const request: DataRequest = new DataRequest(this.controller, {hideSpinner: true, hideError: true});
    return this.ctWebApiService.post(request, {});

  }

}
