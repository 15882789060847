export class Registration {

  constructor(
    public Avatar: number,
    public Nickname: string,
    public CodiceFiscale: string,
    public Email: string,
    public Password: string
  ) {
  }

}
