<div class="news-cntr eco-21-cntr">

  <div class="container h-100">

    <title-heading [titleHeadingViewModel]="{ title: 'Quiz'}"></title-heading>

    <div class="news-items-cntr">

      <div class="news-item mt-5" *ngFor="let item of news">

        <div class="d-flex">

          <img class="news-item-img" [src]="item.image"/>

          <div>

            <h1 class="text-start mt-4 dark-green">{{item.title}}</h1>

            <p class="font-weight-bolder">{{item.description}}</p>

          </div>

        </div>

        <button class="btn w-100" (click)="goTo(item.path)">Scopri</button>

      </div>

    </div>

  </div>

</div>
