import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {GoogleInputAutocompleteComponent} from './google-input-autocomplete.component';
import {MatGoogleMapsAutocompleteModule} from '@angular-material-extensions/google-maps-autocomplete';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatLegacyFormFieldModule as MatFormFieldModule} from "@angular/material/legacy-form-field";
import {MatLegacyInputModule as MatInputModule} from "@angular/material/legacy-input";

@NgModule({
  declarations: [
    GoogleInputAutocompleteComponent
  ],
  imports: [
    CommonModule,
    MatGoogleMapsAutocompleteModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule
  ],
  exports: [
    GoogleInputAutocompleteComponent
  ]
})
export class GoogleInputAutocompleteModule {
}
