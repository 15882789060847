import {Component} from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { NavigationEnd, Router } from '@angular/router';
// import { CookieAcceptComponent } from './components/cookie-accept/cookie-accept.component';
import {AuthGuardService} from "./core/lib/auth/auth-guard.service";
import { CookieService } from './core/lib/cookie.service';
import {CtWebviewService} from "@ctsolution/ct-webview";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor(
    public AuthService: AuthGuardService,
    private route: Router,
    private cookieService: CookieService,
    private matBottomSheet: MatBottomSheet,
    private ctWebviewService: CtWebviewService
  ) {

    this.ctWebviewService.subscribeToInAppOpeningIntents();

  }

  ngOnInit() {
    this.route.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0)
    });

    // if (!this.cookieService.getAcceptCookie()) {

    //   this.matBottomSheet.open(CookieAcceptComponent, {
    //     disableClose: true,
    //     panelClass: 'cookie-bottom-sheet-cntr'
    //   });

    // }
  }

}
