import {Injectable} from '@angular/core';
import {CtWebviewService} from '@ctsolution/ct-webview';

@Injectable({
  providedIn: 'root'
})
export class CookieService {

  private readonly cookieAccept = 'cookie_accept';

  constructor(private ctWebViewService: CtWebviewService) {
  }

  setAcceptCookie = (): void => localStorage.setItem(this.cookieAccept, 'true');

  

  

  getAcceptCookie(): boolean {

    const cookieAcceptValue: string | null = localStorage.getItem(this.cookieAccept);


    if (this.ctWebViewService.isCtWebView()) {

      return true;

    }

    if (cookieAcceptValue) {

       return JSON.parse(cookieAcceptValue); 

    }

    return false;

  }

}



