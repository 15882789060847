<div class="eco-21-cntr available-quiz-cntr h-100">

  <title-heading [titleHeadingViewModel]="{ title: 'Quante ne sai?', subtitle: 'Quiz'}"></title-heading>

  <div class="container h-100">

    <div class="quiz-items-cntr">

      <ng-container *ngIf="questionari.length > 0; else defaultMessage">

        <div class="quiz-item" *ngFor="let questionario of questionari">

          <h2 class="m-3 text-center">{{questionario.nome}}</h2>

          <p class="ms-3 me-3">{{questionario.descrizione}} </p>

          <button class="w-100 btn"  [routerLink]="['questionario', questionario.id]">Inizia</button>

        </div>

      </ng-container>

      <ng-template #defaultMessage>

        <div class="text-center">

          <h3><b>Non ci sono quiz disponibili.</b></h3>

        </div>

      </ng-template>

    </div>

  </div>

</div>
