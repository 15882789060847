<div class="familiar-signup-cntr h-100">

  <div class="container mt-4">

    <h1 class="primary-color text-center mb-4">Completa la registrazione</h1>

    <form class="signupform_cntr" [formGroup]="form" (ngSubmit)="register()">

      <div class="avatar-choise-cntr pb-4">

        <div class="container row m-0">

          <div class="ecoxxi-avatar avatar-1 col-6" [class.off]="selectedGender !== 1" (click)="selectedGender = 1"
            [class.jello-horizontal]="femaleBounce" (mouseover)="femaleBounce=true" (mouseout)="femaleBounce=false">
          </div>

          <div class="ecoxxi-avatar avatar-2 col-6" [class.off]="selectedGender !== 2" (click)="selectedGender = 2"
            [class.jello-horizontal]="maleBounce" (mouseover)="maleBounce=true" (mouseout)="maleBounce=false">
          </div>

        </div>

      </div>

      <div class="complete-registration-cntr">

        <div class="mb-4">

          <form-input [parameters]="nickname"></form-input>
  
        </div>
        <div class="mb-4">
  
          <form-input [parameters]="email"></form-input>
  
        </div>
        <div class="mb-4">
  
          <form-input [parameters]="password"></form-input>
  
        </div>
  
        <div class="mt-5 text-center">
  
          <ct-button [parameters]="registerButton"></ct-button>
  
        </div>

      </div>  

    </form>

  </div>

</div>