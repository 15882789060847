<div class="d-flex justify-content-end mb-2">
  <mat-icon class="light-green font-weight-bolder" (click)="close()">close</mat-icon>
</div>
<h1 class="text-center" class="primary-color font-weight-bolder">CONDIZIONI GENERALI</h1>
<p>
  Questo sito web utilizza i cookie. Utilizziamo i cookie per personalizzare contenuti ed annunci, per fornire
  funzionalità dei social media e per analizzare il nostro traffico. Condividiamo inoltre informazioni sul modo in
  cui
  utilizza il nostro sito con i nostri partner che si occupano di analisi dei dati web, pubblicità e social media,
  i
  quali potrebbero combinarle con altre informazioni che ha fornito loro o che hanno raccolto dal suo utilizzo dei
  loro servizi.
</p>
<p>
  I cookie sono piccoli file di testo che possono essere utilizzati dai siti web per rendere più efficiente
  l’esperienza per l’utente.
</p>
<p>
  La legge afferma che possiamo memorizzare i cookie sul suo dispositivo se sono strettamente necessari per il
  funzionamento di questo sito. Per tutti gli altri tipi di cookie abbiamo bisogno del suo permesso.
</p>
<p>
  Questo sito utilizza diversi tipi di cookie. Alcuni cookie sono collocati da servizi di terzi che compaiono
  sulle
  nostre pagine.
</p>
