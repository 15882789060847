import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TargetsComponent} from './targets.component';
import {MatIconModule} from '@angular/material/icon';
import {TitleHeadingModule} from 'src/app/components/title-heading/title-heading.module';
import {ChartsModule} from "ng2-charts";

@NgModule({
  imports: [
    CommonModule,
    TitleHeadingModule,
    MatIconModule,
    ChartsModule
  ],
  declarations: [TargetsComponent],
  exports: [TargetsComponent]
})
export class TargetsModule {
}
