import {Component, OnInit} from '@angular/core';
import {RankController} from "../../core/controllers/rank-controller";
import {RankingGetParameter} from "../../core/classes/ranking-get-parameter";
import {RANKING_TYPE} from "../../core/enums/ranking-type";

@Component({
  selector: 'app-rank',
  templateUrl: './rank.component.html',
  styleUrls: ['./rank.component.scss']
})
export class RankComponent implements OnInit {

  utentiSettimanali: Array<Utente> = [];   //new Utente('giacomo','',23,2222),
  utentiMensili: Array<Utente> = [];

  constructor(private rankController: RankController) {
  }

  ngOnInit() {

    this.getWeeklyRank();
    this.getMonthlyRank();

  }

  getWeeklyRank = () => {

    const parameter: RankingGetParameter = this.getRankingParameter(RANKING_TYPE.WEEKLY);

    this.getRank(parameter)
      .then((result: Array<Utente>) => this.utentiSettimanali = result);

  };

  getMonthlyRank = () => {

    const parameter: RankingGetParameter = this.getRankingParameter(RANKING_TYPE.MONTHLY);

    this.getRank(parameter)
      .then((result: Array<Utente>) => this.utentiMensili = result);

  };

  getRank = (parameter: RankingGetParameter): Promise<any> => this.rankController.get(parameter);

  getRankingParameter = (type: RANKING_TYPE) => new RankingGetParameter(type, new Date());

}


export class Utente {

  constructor(public userName: string, public avatarCode: string, public level: number, public userPoints: number) {
  }

}
