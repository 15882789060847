import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HistoricalComponent } from './historical.component';
import { MedalsModule } from './_components/medals/medals.module';
import { AccumulatedBalanceModule } from './_components/accumulated-balance/accumulated-balance.module';
import { ConfermentsListModule } from './_components/conferments-list/conferments-list.module';
import { TitleHeadingModule } from 'src/app/components/title-heading/title-heading.module';

@NgModule({
  declarations: [HistoricalComponent],
  imports: [
    CommonModule,
    TitleHeadingModule,
    MedalsModule,
    AccumulatedBalanceModule,
    ConfermentsListModule
  ],
  exports: [HistoricalComponent]
})
export class HistoricalModule { }
