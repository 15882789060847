<div class="logged-home-cntr eco-21-cntr h-100">

  <div class="mb-5 header-background text-uppercase">

    <h1 class="text-center font-weight-bolder primary-color">Ciao {{user.Username}}</h1>

    <div class="d-flex justify-content-center" *ngIf="avatar">

      <div class="profile-avatar m-auto" [ngStyle]="{'background': 'url('+ avatar.getFullPath() +')'}"></div>

    </div>

  </div>


  <div class="text-center personal-score-section container">

    <div class="w-75 m-auto separetor"></div>

    <app-personal-total-score></app-personal-total-score>

    <ct-button [parameters]="scoreRedirect"></ct-button>

    <div class="w-75 mb-4 m-auto separetor"></div>

  </div>



  <div class="container d-flex align-items-center flex-column">

    <ng-container *ngFor="let button of viewModel.buttons">

      <div class="mb-4">

        <ct-button [parameters]="button"></ct-button>

      </div>

    </ng-container>

  </div>

</div>