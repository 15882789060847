import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CtCardModule } from '@ctsolution/ct-framework';
import {  CardComponent } from './card.component';

@NgModule({
  imports: [
    CommonModule,
    CtCardModule
  ],
  declarations: [CardComponent],
  exports: [CardComponent]
})
export class CardModule { }
