<div class="coupon-list-cntr mb-4 ml-4 mr-4 pb-5" #scroller>

  <ng-container *ngIf="ecoCouponList && ecoCouponList.length; else no_coupons">

    <div class="container h-100">

      <ng-container *ngFor="let ecoCoupon of ecoCouponList">

          <div class="mb-4">

            <div class="font-weight-bolder dark-green" [innerHTML]="ecoCoupon.partnerName"></div>
  
            <p class="font-style-italic primary-color mt-2">{{ecoCoupon.productAmount}}  PUNTI</p>
    
            <div class="conferment-message mt-1 mb-2 font-weight-light" [innerHTML]="ecoCoupon.productDescription"></div>
  
            <small class="light-green font-weight-light" [innerHTML]="this.formatDate(ecoCoupon.despatchedAt)"></small>

          </div>

      </ng-container>

    </div>

  </ng-container>

  <ng-template #no_coupons>

    <div class="text-center">

      <b>Non hai evaso nessun coupon.</b>

    </div>

  </ng-template>

</div>