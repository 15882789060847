import { ValidatorFn, Validators } from "@angular/forms";

export class FormInputValidationValue{
    private _name!: string;
    public get name(): string {
        return this._name;
    }
    public set name(value: string) {
        this._name = value;
    }
    private _validator!: ValidatorFn;
    public get validator(): ValidatorFn {
        return this._validator;
    }
    public set validator(value: ValidatorFn) {
        this._validator = value;
    }

    private _errorMessageKey: string;
    public get errorMessageKey(): string {
        return this._errorMessageKey;
    }
    public set errorMessageKey(value: string) {
        this._errorMessageKey = value;
    }

    private constructor(name:string, validator:ValidatorFn, errorMessaggeKey:string){
        this._name = name;
        this._validator = validator;
        this._errorMessageKey = errorMessaggeKey;
    }
    public static Create(name:string, validator:ValidatorFn, errorMessageKey:string):FormInputValidationValue {
        return new FormInputValidationValue(name, validator, errorMessageKey);
    }
}

export class FormInputValidationStandard {
    public static Required = ():FormInputValidationValue => FormInputValidationValue.Create("required", Validators.required,"Campo obbligatorio");  //TODO Marghe: sostituire con il pacchetto con chiave - valore nel json
    public static RequiredTrue = ():FormInputValidationValue => FormInputValidationValue.Create("requiredtrue", Validators.requiredTrue,"VALIDATOR.REQUIREDTRUE");
    public static MinLength = (minLength:number):FormInputValidationValue => FormInputValidationValue.Create("minlength", Validators.minLength(minLength),"Il numero minimo dei caratteri non è valido");
    public static Min = (min:number):FormInputValidationValue => FormInputValidationValue.Create("min", Validators.min(min),"Il campo non è valido");
    public static MaxLength = (maxLength:number):FormInputValidationValue => FormInputValidationValue.Create("maxlength", Validators.maxLength(maxLength),"Il numero massimo dei caratteri non è valido");
    public static Max = (max:number):FormInputValidationValue => FormInputValidationValue.Create("max", Validators.max(max),"Il campo non è valido");
    public static Email = ():FormInputValidationValue => FormInputValidationValue.Create("email", Validators.email,"La mail non è corretta");
    public static NullValidator = ():FormInputValidationValue => FormInputValidationValue.Create("nullvalidator", Validators.nullValidator,"Il campo non è valido");
    public static Pattern = (pattern:string | RegExp):FormInputValidationValue => FormInputValidationValue.Create("pattern", Validators.pattern(pattern),"La pattern non è valida");
}

