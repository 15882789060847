import {Injectable} from '@angular/core';
import {CtWebapiService, DataRequest} from '@ctsolution/ct-webapi';
import {IGeographicArea} from '../interfaces/geographic-area';

@Injectable({providedIn: 'root'})
export class AreaGeograficaController {

  private areasList: IGeographicArea[] = [];
  private controller: string = 'AreaGeografica';

  constructor(private ctWebApiService: CtWebapiService) {
  }

  get(): Promise<IGeographicArea[]> {

    return new Promise<IGeographicArea[]>((resolve) => {

      if (this.areasList.length > 0) {

        resolve(this.areasList);
        return;

      }

      const request: DataRequest = new DataRequest(`${this.controller}/Get`);

      this.ctWebApiService
        .get(request)
        .subscribe((r: any) => {

          if (r && r.result) {

            this.areasList = r.result;

          }

          resolve(this.areasList);

        });

    });

  }

}
