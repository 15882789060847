import {Component, EventEmitter, Input, Output} from '@angular/core';
import PlaceResult = google.maps.places.PlaceResult;
import {GoogleInputAutocompleteConfiguration} from "./google-input-autocomplete.configuration";
import {Location} from '@angular-material-extensions/google-maps-autocomplete';

@Component({
  selector: 'app-google-input-autocomplete',
  templateUrl: './google-input-autocomplete.component.html',
  styleUrls: ['./google-input-autocomplete.component.scss']
})
export class GoogleInputAutocompleteComponent {

  @Input() configuration!: GoogleInputAutocompleteConfiguration;
  @Output() onLocationChange: EventEmitter<Location> = new EventEmitter<Location>();

  constructor() {
  }

  onAutocompleteSelected(result: PlaceResult) {

    this.configuration.control.setValue(result.formatted_address ?? null);

  }

  onLocationSelected(location: Location) {

    this.onLocationChange.emit(location);

  }

}
