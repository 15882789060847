import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer/footer.component';
import { RouterModule } from '@angular/router';
import { CtButtonModule } from '@ctsolution/ct-framework';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    CtButtonModule,
    MatIconModule,
    MatListModule
  ],
  declarations: [
    FooterComponent
  ],
  exports: [
    FooterComponent,
    RouterModule
  ]
})
export class LayoutModule { }