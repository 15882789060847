import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EcoCouponsComponent } from './eco-coupons.component';
import { TitleHeadingModule } from 'src/app/components/title-heading/title-heading.module';

import { PartnersCouponController } from 'src/app/core/controllers/partner-coupon-controller';
import { EcoCouponListComponent } from './eco-coupon-list/eco-coupon-list.component';
import { HistoricalEcoCouponsComponent } from './historical-eco-coupons/historical-eco-coupons.component';
import { CtButtonModule } from '@ctsolution/ct-framework';
import { EcoCouponRequestComponent } from './eco-coupon-list/eco-coupon-request/eco-coupon-request.component';
import { CouponController } from 'src/app/core/controllers/coupon-controller';
import { DisponibilitaController } from 'src/app/core/controllers/disponibilita-controller';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';


@NgModule({
  imports: [
    CommonModule,
    TitleHeadingModule,
    CtButtonModule,
    MatBottomSheetModule,
    MatIconModule,
    RouterModule
 
  ],
  declarations: [EcoCouponsComponent, EcoCouponListComponent, HistoricalEcoCouponsComponent, EcoCouponRequestComponent],
  exports: [EcoCouponsComponent,  EcoCouponListComponent, HistoricalEcoCouponsComponent],
  providers: [PartnersCouponController, CouponController, DisponibilitaController]
})
export class EcoCouponsModule { }
