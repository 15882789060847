import {CallResult} from './../classes/call-result';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {CtWebapiService, DataRequest} from '@ctsolution/ct-webapi';
import {Profile} from '../classes/profile';

@Injectable()
export class ProfileController {

  private controller: string = 'profilo';

  constructor(private ctWebApiService: CtWebapiService) {
  }

  get(): Observable<CallResult> {

    const request: DataRequest = new DataRequest(this.controller);
    return this.ctWebApiService.get(request);

  }

  update(profileParams: Profile): Observable<CallResult> {

    const request: DataRequest = new DataRequest(this.controller);
    return this.ctWebApiService.put(request, profileParams);

  }

}
