import {Component, OnInit} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {CtButtonParameters} from '@ctsolution/ct-framework';
import {AppGeneralService} from 'src/app/core/lib/app-general.service';
import {AccountController} from "../../../../core/controllers/account-controller";
import {MissionsController} from "../../../../core/controllers/missions-controller";
import {ActivatedRoute} from "@angular/router";
import { FormInputParameters } from 'src/app/components/form-input/class/form-input-parameters';
import { FormInputValidationStandard, FormInputValidationValue } from 'src/app/components/form-input/class/form-input-validation';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
  providers: [AccountController, MissionsController]
})
export class LoginFormComponent implements OnInit {

  form: UntypedFormGroup = new UntypedFormGroup({ });
  passwordType: string = 'password';
  returnUrl: string | null = null;

  email: FormInputParameters = FormInputParameters
  .create('email',this.form)
  .setLabel('Email')
  .addValidator(FormInputValidationStandard.Required())
  .addValidator(FormInputValidationValue.Create("email",Validators.email,"La mail non è valida"));

  password: FormInputParameters = FormInputParameters
  .create('password',this.form)
  .setLabel('Password')
  .setType('password')
  .addValidator(FormInputValidationStandard.Required());



  loginButton: CtButtonParameters = CtButtonParameters
    .create((event) => this.login())
    .setCSS("general-button", true)
    .setContent("Accedi")

  constructor(

    private appGeneralService: AppGeneralService,
    private AccountController: AccountController,
    private route: ActivatedRoute
  ) {

    this.route
      .queryParams
      .subscribe(p => {

        this.returnUrl = p['returnUrl'] ?? null;

      });

  }

  ngOnInit() {
  }

  changePasswordType(event: { checked: boolean }) {

    this.password.setType(event.checked ? 'text' : 'password');

  }

  goToRecoveryPassword() {

    this.appGeneralService.navigateTo('/accesso/recupero-password')

  }

  login() {

    if (this.form.valid) {

      const formValue = this.form.value;

      this.AccountController
        .login(formValue.email, formValue.password, false, this.returnUrl);

    }

  }

}
