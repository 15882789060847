import {CallResult} from './../classes/call-result';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {CtWebapiService, DataRequest} from '@ctsolution/ct-webapi';
import {PartnersResearch} from '../classes/partners-research';
import {ICallResult} from "../interfaces/Icall-result";
import {PartnersResearchResults} from "../interfaces/partners-research-results";

@Injectable({providedIn: 'root'})
export class PartnersController {

  private controller: string = 'partner';
  private partnersList: Array<PartnersResearchResults> = [];

  constructor(private ctWebApiService: CtWebapiService) {
  }

  get(id: number): Observable<CallResult> {

    const request: DataRequest = new DataRequest(`${this.controller}/${id}`, {hideSpinner: true});
    return this.ctWebApiService.get(request);

  }

  getAllPartners(): Promise<Array<PartnersResearchResults>> {

    return new Promise<Array<PartnersResearchResults>>((resolve) => {

      if (this.partnersList.length > 0) {

        resolve(this.partnersList);
        return;

      }

      const request: DataRequest = new DataRequest(this.controller);

      this.ctWebApiService
        .post(request, new PartnersResearch())
        .subscribe((r: ICallResult) => {

          this.partnersList = r.result;
          resolve(this.partnersList)

        })

    });

  }

}
