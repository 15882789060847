import {Component, OnInit} from '@angular/core';
import {BalanceController} from 'src/app/core/controllers/balance-controller';

@Component({
  selector: 'app-accumulated-balance',
  templateUrl: './accumulated-balance.component.html',
  styleUrls: ['./accumulated-balance.component.scss'],
  providers: [BalanceController]
})
export class AccumulatedBalanceComponent {

  balance: number = 0;

  constructor(private balanceController: BalanceController) {

    this.balanceController
      .get()
      .subscribe((result: any) => this.balance = result.result.totalPoints);

  }

}
