import {NgModule} from '@angular/core';
import {NewsModule} from './news/news.module';
import {TargetsModule} from './targets/targets.module';
import {ProfileModule} from './profile/profile.module';
import {SettingsModule} from './settings/settings.module';
import {HistoricalModule} from './historical/historical.module';
import {SearchModule} from './home/search/search.module';
import {LoggedHomeModule} from './home/logged-home/logged-home.module';
import {RankModule} from "./rank/rank.module";
import {ConferimentoModule} from "./conferimento/conferimento.module";
import { EcoCouponsModule } from './eco-coupons/eco-coupons.module';




@NgModule({
  imports: [
    NewsModule,
    TargetsModule,
    ProfileModule,
    SettingsModule,
    SearchModule,
  
   EcoCouponsModule,
    HistoricalModule,
    LoggedHomeModule,
    RankModule,
    ConferimentoModule
  ]
})
export class PagesModule {
}
