import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {CallResult} from './../classes/call-result';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {CtWebapiService, DataRequest} from '@ctsolution/ct-webapi';
import {FamiliarData} from '../classes/familiar-data';
import {RegisterFamiliarData} from '../classes/register-familiar-data';
import { AccountController } from './account-controller';
import { RegistrationWelcomeComponent } from 'src/app/authentication/sign-up/registration-welcome/registration-welcome.component';
import { ICallResult } from '../interfaces/Icall-result';

@Injectable()
export class FamiliarController {

  private controller: string = 'famiglia';

  constructor(
    private accountController: AccountController,
    private MatDialog: MatDialog,
    private ctWebApiService: CtWebapiService
  ) {
  }

  addFamiliar(familiar: FamiliarData): Observable<ICallResult> {

    const request: DataRequest = new DataRequest(this.controller);
    return this.ctWebApiService.post(request, familiar);

  }

  registerFamiliar(familiar: RegisterFamiliarData): void {

    const request: DataRequest = new DataRequest(this.controller);

    this.ctWebApiService
      .put(request, familiar).subscribe((result) => {

      this.accountController.login(familiar.Email,  familiar.Password, false, null);

      this.MatDialog.open(RegistrationWelcomeComponent, {
        width: '300px',
        panelClass: 'plastipremia-dialog',
      });

    });

  }

  getFamiliars(): Observable<CallResult> {

    const request: DataRequest = new DataRequest(this.controller);
    return this.ctWebApiService.get(request);

  }

}
