import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TitleHeadingComponent } from './title-heading.component';
import { BackButtonModule } from '../back-button/back-button.module';

@NgModule({
  imports: [
    CommonModule,
    BackButtonModule
  ],
  declarations: [TitleHeadingComponent],
  exports: [TitleHeadingComponent]
})
export class TitleHeadingModule { }
