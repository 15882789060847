import {AccountController} from './account-controller';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {Injectable} from '@angular/core';
import {CtWebapiService, DataRequest} from '@ctsolution/ct-webapi';
import {Registration} from '../classes/registration';
import {
  RegistrationWelcomeComponent
} from "../../authentication/sign-up/registration-welcome/registration-welcome.component";

@Injectable()
export class RegistrationController {

  private controller: string = 'profilo';


  constructor(
    private ctWebApiService: CtWebapiService,
    private MatDialog: MatDialog,
    private AccountController: AccountController,
  ) {
  }

  signup(Avatar: number, Nickname: string, CodiceFiscale: string, Email: string, Password: string): void {

    const registrationParams = new Registration(Avatar, Nickname, CodiceFiscale, Email, Password);
    const request: DataRequest = new DataRequest(this.controller);

    this.ctWebApiService.post(request, registrationParams)
      .subscribe((result) => {

        this.AccountController.login(Email, Password, true, null);

        this.MatDialog.open(RegistrationWelcomeComponent);

      });

  }

}
