import {Injectable} from '@angular/core';
import {Avatar, Avatars} from './avatar';
import {AvatarController} from '../../controllers/avatars-controller';

@Injectable({
  providedIn: 'root'
})
export class AvatarService {

  Avatars: Array<Avatar> = Avatars;

  constructor(private _controller: AvatarController) {
  }

  getAvatarByCode = (code: number): Avatar | undefined => this.Avatars.find(avatar => avatar.code == code);

  getUserAvatar(): Promise<Avatar> {

    return new Promise<Avatar>((resolve) => {

      this._controller
        .get()
        .subscribe((result: any) => {

          const returnValue: Avatar = this.getAvatarByCode(result['result'])!;
          resolve(returnValue);

        });

    });

  }

  putUserAvatar(code: number): Promise<boolean> {

    return new Promise<boolean>((resolve) => {

      this._controller
        .update(code).subscribe(() => resolve(true));

    });

  }

}
