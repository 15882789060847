<div class="coupon-confirm-cntr">

  <div class="mb-2 mt-2">

    <h2 class="font-weight-bolder dark-green">Confermi di trovarti davanti all'esercente <span class="font-weight-bolder primary-color font-style-italic">{{data.partnerName}} </span>e di voler evadere questo coupon?</h2>
 

  </div>

  <div class="mb-3">

    <small>*Una volta utilizzato il coupon, non potrai riutilizzarlo una seconda volta</small>

  </div>

  <div class="w-75 m-auto d-flex mb-5 justify-content-around">

    <button mat-button (click)="redeem()" style="border: none; background-color: var(--light-green); color: white; padding: 10px 20px; border-radius: 10px; width: 110px;">Evadi</button>
    <button mat-button class="cancel" (click)="cancel()" style="border: none; background-color: var(--primary-color); color: white; padding: 10px 20px; border-radius: 10px; width: 110px;;">Annulla</button>

  </div>

</div>

