import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MedalsComponent } from './medals.component';

@NgModule({
  declarations: [MedalsComponent],
  imports: [CommonModule],
  exports: [MedalsComponent]
})
export class MedalsModule { }
